    .the1-st0{fill:url(#the1-blob_1_);}
    .the1-st1{fill:#F39D80;}
    .the1-st2{fill:#2E3652;}
    .the1-st3{fill:#F9B07D;}
    .the1-st4{fill:#233862;}
    .the1-st5{fill:#F79647;}
    .the1-st6{fill:#EB7D29;}
    .the1-st7{fill:#183660;}
    .the1-st8{fill:#EC965E;}
    .the1-st9{fill:#FFFFFF;}
    .the1-st10{fill:#2B478B;}
    .the1-st11{fill:#2A4E96;}
    .the1-st12{fill:#B1C0E2;}
    .the1-st13{fill:#8B654A;}
    .the1-st14{fill:#A0AED8;}
    .the1-st15{fill:#74543B;}
    .the1-st16{fill:#D96627;}
    .the1-st17{fill:#FFAF34;}
    .the1-st18{fill:#FFCA38;}
    .the1-st19{fill:#6CB6BE;}
    .the1-st20{fill:#86CFCC;}
    .the1-st21{fill:#5C82C2;}
    .the1-st22{fill:#7A95CC;}