.profile-container {
    position: relative;
    width: 100%;
    .anchor {
        position: absolute;
        top: 40vw;
        transform: translateY(-50vh);
    }
    .profile-intro-container {
        position: relative;
        z-index: 1;
        pointer-events: none;
        .profile-background {
            position: relative;
            z-index: 1;
        }
        .profile-intro {
            z-index: 2;
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            color: $white;
            padding-top: 50vw;
            @media (min-width: 480px) {
                padding-top: 30vw;
            }
            @include media-breakpoint-up(sm) {
                padding-top: 15vw;
            }
            @include media-breakpoint-up(md) {
                padding-top: 0;
            }
            .profile-intro-content {
                position: relative;
                @include responsive-properties('all',
                    (padding-top, 0, 2em),
                    (padding-left, 0, 2em)
                );
                .profile-title {
                    display: flex;
                    margin-left: 2rem;
                    @include media-breakpoint-up(md) {
                        margin-left: 3rem;
                    }
                    .profile-tag-container {
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        border-right: 1px solid $white;
                        margin-right: 1rem;
                        @include media-breakpoint-up(md) {
                            margin-right: 1.5rem;
                            padding-bottom: 1em;
                        }
                        @include media-breakpoint-up(xl) {
                            margin-right: 2rem;
                        }
                        @include responsive-property(font-size, 12px, 16px);
                    }
                    .profile-tag {
                        position: absolute;
                        font-weight: $weight-semibold;
                        text-transform: uppercase;
                        letter-spacing: 0.1em;
                        transform-origin: left top;
                        transform: rotate(-90deg) translate(-50%, -100%);
                        padding-bottom: 0.75rem;
                        @include media-breakpoint-up(md) {
                            transform: rotate(-90deg) translate(-75%, -100%);
                            padding-bottom: 1rem;
                        }
                        @include media-breakpoint-up(xl) {
                            padding-bottom: 1.5rem;
                        }
                    }
                    h1 {
                        margin: 0;
                        & > div > div,
                        & > div > span > div {
                            white-space: nowrap;
                        }
                    }
                }
                p {
                    @include media-breakpoint-up(md) {
                        margin-left: calc(4.5rem + 1px);
                    }
                    @include media-breakpoint-up(xl) {
                        margin-left: calc(5rem + 1px);
                        margin-top: 1.5rem;
                    }
                }
            }
        }
        .profile-line-container {
            position: absolute;
            width: 100%;
            top: 25%;
            left: 40%;
            transform: scale(1.125);
        }
        .profile-image-container {
            position: relative;
        }
        .profile-intro-content-col,
        .profile-intro-image-col {
            @include make-col-ready();
        }
        .profile-intro-content-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 1;
            @include media-breakpoint-up(xl) {
                @include make-col(13);
            }
        }
        .profile-intro-image-col {
            @include make-col(18);
            @include media-breakpoint-up(sm) {
                @include make-col(16);
            }
            @include media-breakpoint-up(md) {
                @include make-col(14);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(12);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(11);
            }
        }
    }
    .profile-banner-content-container,
    .profile-banner-image-container {
        margin-top: calc(-50vh - 18vw);
        width: 100%;
        height: 0;
        width: 100%;
        padding-top: 50%;
    }
    .profile-banner-content,
    .profile-banner-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .profile-banner-content-container {
        z-index: 2;
        position: absolute;
        .profile-banner-content {
            display: flex;
            align-items: flex-end;
            color: $white;
            text-shadow: 0 0 1em rgba(0,0,0,0.5);
            padding-bottom: 2em;
            h1 {
                margin: 0;
            }
            .profile-banner-caption {
                font-family: $font-serif;
                font-style: italic;
                display: none;
                @include media-breakpoint-up(sm) {
                    display: block;
                }
                @include responsive-property(font-size, 1em, 1.5em);
            }
        }
    }
    .profile-banner-image-container {
        z-index: 0;
        position: relative;
        .profile-banner-image {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.25);
                background: linear-gradient(0deg, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0) 100%);
            }
        }
    }
    .profile-article-container {
        z-index: 0;
        position: relative;
        .profile-article {
            padding: 1.5em 0;
            @include media-breakpoint-up(xl) {
                padding: 2em 0;
            }
            h2 {
                color: $cobalt;
            }
            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 0 2em 0 2em;
            }
            .profile-article-excerpt {
                color: $amethyst;
                font-weight: $weight-bold;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                margin: 1.5rem 0;
                @include responsive-properties('all',
                    (font-size, 1em, 1.25em),
                    (line-height, 1.5, 1.4)
                );
            }
            .profile-article-image {
                max-width: 640px;
                margin: 0 auto;
            }
            .order-xl-1 {
                & > hr:first-child {
                    margin-top: 2em;
                }
                @include media-breakpoint-up(xl) {
                    padding-right: 3rem;
                    & > hr:first-child {
                        display: none;
                    }
                }
            }
            .order-xl-2 {
                @include media-breakpoint-up(xl) {
                    padding-left: 3rem;
                    border-left: 1px solid $black;
                }
            }
        }
    }
    &.profile-f10 {
        .profile-intro-content-col {
            @include media-breakpoint-up(xl) {
                order: 2;
            }
        }
        .profile-intro-image-col {
            @include media-breakpoint-up(xl) {
                order: 1;
            }
        }
        .profile-line-container {
            left: -40%;
        }
    }
}