@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .text#{$infix}-left   { text-align: left !important; }
        .text#{$infix}-right  { text-align: right !important; }
        .text#{$infix}-center { text-align: center !important; }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .h#{$infix}-100   { height: 100% !important; }
        .h#{$infix}-auto  { height: auto !important; }
    }
}
