	.f2-st0{fill:#9595EF;}
	.f2-st1{fill:#3F3F75;}
	.f2-st2{fill:#2C2C5B;}
	.f2-st3{fill:#353568;}
	.f2-st4{fill:#47478E;}
	.f2-st5{fill:#5252A0;}
	.f2-st6{fill:#151348;}
	.f2-st7{fill:#FFC114;}
	.f2-st8{fill:#373193;}
	.f2-st9{fill:#FFB69C;}
	.f2-st10{fill:#221C6F;}
	.f2-st11{fill:#FFFFFF;}
	.f2-st12{fill:url(#f2-XMLID_2_);}
	.f2-st13{fill:url(#f2-XMLID_3_);}
	.f2-st14{fill:#5856CC;}
	.f2-st15{fill:url(#f2-XMLID_4_);}
	.f2-st16{fill:url(#f2-XMLID_5_);}
	.f2-st17{fill:url(#f2-XMLID_6_);}
	.f2-st18{fill:url(#f2-XMLID_7_);}
	.f2-st19{fill:url(#f2-XMLID_8_);}
	.f2-st20{fill:#FFA081;}
	.f2-st21{fill:#004B88;}
	.f2-st22{fill:#8C8CDD;}
	.f2-st23{fill:#4B4B96;}

	/* f2-leaves */
	.f2-leaf-st1{fill:#3F3F75;}
	.f2-leaf-st2{fill:#353568;}
	.f2-leaf-st3{fill:#2C2C5B;}