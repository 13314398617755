// modal__stat

.modal__stat {
    width: 95vw;
    height: 90vh;
    .modal__inner {
        padding: 0;
        height: 100%;
        .container-fluid {
            height: 100%;
            & > .row {
                height: 100%;
            }
        }
        h1, h2, h3, h4 {
            color: $cobalt;
        }
        h1 {
            font-family: $font-sans;
            @include responsive-properties('all',
                (font-size, 1.5em, 4em),
                (line-height, 1.3, 1.1)
            );
        }
        h2 {
            font-family: $font-sans;
            @include responsive-properties('all',
                (font-size, 1.1em, 2.5em),
                (line-height, 1.4, 1.1)
            );
        }
        h3 {
            font-family: $font-sans;
            @include responsive-properties('all',
                (font-size, 1.1em, 2em),
                (line-height, 1.4, 1.1)
            );
        }
        h4 {
            font-family: $font-sans;
            @include responsive-properties('all',
                (font-size, 1.1em, 1.5em),
                (line-height, 1.4, 1.1)
            );
        }
        hr {
            border-top: 1px solid $cobalt;
        }
        .modal-col-1,
        .modal-col-2 {
            @include make-col-ready();
        }
        .modal-col-1 {
            @include media-breakpoint-up(lg) {
                @include make-col(14);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(15);
            }
            @include media-breakpoint-up(x2) {
                @include make-col(16);
            }
            @include media-breakpoint-up(x3) {
                @include make-col(17);
            }
            @media (min-width: $x4) {
                @include make-col(18);
            }
        }
        .modal-col-2 {
            @include media-breakpoint-up(lg) {
                @include make-col(10);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(9);
            }
            @include media-breakpoint-up(x2) {
                @include make-col(8);
            }
            @include media-breakpoint-up(x3) {
                @include make-col(7);
            }
            @media (min-width: $x4) {
                @include make-col(6);
            }
        }
        .modal-col-1 {
            position: relative;
            @include media-breakpoint-up(lg) {
                height: 100%;
                overflow-y: auto;
            }
            .modal__content {
                @include media-breakpoint-up(sm) {
                    padding: $grid-gutter-width / 2;
                }
            }
            .modal__header {
                padding-bottom: $grid-gutter-width / 2;
                border-bottom: 1px solid $teal;
                .modal-header-col-1,
                .modal-header-col-2 {
                    @include make-col-ready();
                }
                .modal-header-col-1 {
                    z-index: 1;
                    @include media-breakpoint-up(lg) {
                        @include make-col(12);
                        display: flex;
                        align-items: flex-end;
                    }
                }
                .modal-header-col-2 {
                    @include media-breakpoint-up(lg) {
                        @include make-col(12);
                        display: flex;
                        align-items: flex-end
                    }
                }
                .modal-header-image {
                    width: 100%;
                    img {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: auto;
                        @include media-breakpoint-up(lg) {
                            max-width: 420px;
                        }
                    }
                }
            }
            .modal__main {
                padding-top: $grid-gutter-width / 2;
            }
            .modal-col-12 {
                @include make-col-ready();
                @include media-breakpoint-up(md) {
                    @include make-col(12);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(24);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(12);
                }
            }
        }
        .modal-col-2 {
            background: #2757e1;
            background: linear-gradient(90deg, rgba(10,164,184,1) 0%, rgba(39,87,225,1) 100%);
            color: $white;
            border-radius: 0 0 0 2em;
            @include media-breakpoint-up(lg) {
                height: 100%;
                border-radius: 0 2em 0 0;
            }
            h2, h3, h4 {
                color: $white;
            }
            .modal__sidebar {
                height: 100%;
                display: flex;
                align-items: center;
                @include responsive-property(padding, 1.5em, 3em, 'all');
                padding: 0;
                @include media-breakpoint-up(sm) {
                    padding: $grid-gutter-width / 2;
                }
                .modal-sidebar-nav {
                    width: 100%;
                    padding-bottom: 3em;
                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li {
                            display: block;
                            border-bottom: 1px solid $white;
                            &:first-child {
                                border-top: 1px solid $white;
                            }
                            a {
                                display: flex;
                                align-items: center;
                                white-space: nowrap;
                                text-transform: uppercase;
                                text-decoration: none;
                                font-weight: $weight-medium;
                                color: $white;
                                padding: 0.5em 0;
                                &:hover {
                                    text-decoration: none;
                                    background-color: $cobalt;
                                }
                                &.active {
                                    background-color: $teal-dark;
                                }
                                &:hover,
                                &.active {
                                    &:before {
                                        content: '\f105';
                                        display: inline-block;
                                        font-family: $fa;
                                        font-weight: $fa-regular-weight;
                                        font-size: 0.9em;
                                        padding: 0 0.5rem;
                                    }
                                }
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .financials-table {
            .numeric {
                font-family: 'Roboto Mono', monospace;
            }
            .financials-left {
                @include make-col-ready();
                @include make-col(12);
                @include media-breakpoint-up(sm) {
                    @include make-col(13);
                }
                @include media-breakpoint-up(md) {
                    @include make-col(14);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(12);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(14);
                }
                @include media-breakpoint-up(x2) {
                    @include make-col(16);
                }
            }
            .financials-right {
                @include make-col-ready();
                @include make-col(12);
                @include media-breakpoint-up(sm) {
                    @include make-col(11);
                }
                @include media-breakpoint-up(md) {
                    @include make-col(10);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(12);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(10);
                }
                @include media-breakpoint-up(x2) {
                    @include make-col(8);
                }
            }
            hr {
                margin: 1em auto;
                &.thick {
                    border-width: 3px;
                }
            }
            p {
                margin: 0.5em 0;
                line-height: 1;
            }
        }
        .figure,
        .legend {
            margin-left: auto;
            margin-right: auto;
            @include responsive-property(max-width, 100%, 66.66667%);
        }
        .the-logo {
            display: block;
            max-width: 280px;
            height: auto;
        }
    }
}