    .f18-st0{fill:url(#f18-blob_1_);}
    .f18-st1{fill:#EBF3FA;}
    .f18-st2{fill:#2E3552;}
    .f18-st3{fill:#FFB27D;}
    .f18-st4{fill:#233862;}
    .f18-st5{fill:#1C468A;}
    .f18-st6{fill:#F99746;}
    .f18-st7{fill:#EF7E29;}
    .f18-st8{fill:#132644;}
    .f18-st9{fill:#EFCBAB;}
    .f18-st10{fill:#D8A987;}
    .f18-st11{fill:#ED975D;}
    .f18-st12{fill:#FFFFFF;}
    .f18-st13{fill:#AA443E;}
    .f18-st14{fill:#DDB08E;}
    .f18-st15{fill:#C94C43;}
    .f18-st16{fill:#B56F40;}
    .f18-st17{fill:#99592E;}
    .f18-st18{fill:#2C3A64;}
    .f18-st19{fill:#D2E3FC;}
    .f18-st20{fill:#FFF0E6;}
    .f18-st21{fill:#82B378;}
    .f18-st22{fill:#6BB7BF;}
    .f18-st23{fill:#86D1CD;}
    .f18-st24{fill:#5685D8;}
    .f18-st25{fill:#739AF0;}
    .f18-st26{fill:#ED7D2B;}
    .f18-st3b{fill:#af6e52;}
    .f18-st11b{fill:#7c4f3c;}