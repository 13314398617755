	.f1-st0{fill:#0E4C87;}
	.f1-st1{fill:#FFFFFF;}
	.f1-st2{fill:#C52127;}
	.f1-st3{fill:#FFC114;}
	.f1-st4{fill:#9595EF;}
	.f1-st5{fill:#FFFAEF;}
	.f1-st6{fill:#373193;}
	.f1-st7{fill:#605575;}
	.f1-st8{fill:#D6ECE1;}
	.f1-st9{fill:#383A45;}
	.f1-st10{fill:#70CACD;}
	.f1-st11{fill:#008EA7;}
	.f1-st12{fill:#F58A28;}
	.f1-st13{fill:#F05757;}
	.f1-st14{fill:#C16868;}
	.f1-st15{fill:#9BD5CA;}
	.f1-st16{fill:#A15050;}
	.f1-st17{fill:#221C6F;}
	.f1-st18{fill:#7DD0E6;}
	.f1-st19{fill:#FBE4D3;}
	.f1-st20{fill:#E57373;}
	.f1-st21{fill:#151348;}
	.f1-st22{fill:#FFB69C;}
	.f1-st23{fill:url(#f1-XMLID_13_);}
	.f1-st24{fill:url(#f1-XMLID_14_);}
	.f1-st25{fill:#5856CC;}
	.f1-st26{fill:url(#f1-XMLID_15_);}
	.f1-st27{fill:url(#f1-XMLID_16_);}
	.f1-st28{fill:url(#f1-XMLID_17_);}
	.f1-st29{fill:url(#f1-XMLID_18_);}
	.f1-st30{fill:url(#f1-XMLID_19_);}
	.f1-st31{fill:#FFA081;}