    .f19-st0{fill:url(#f19-blob_1_);}
    .f19-st1{fill:#00A6CE;}
    .f19-st2{opacity:0.51;fill:#00A6CE;}
    .f19-st3{fill:#0083B2;}
    .f19-st4{fill:#213B71;}
    .f19-st5{fill:#00A78D;}
    .f19-st6{opacity:0.34;}
    .f19-st7{fill:#FFFFFF;}
    .f19-st8{fill:#FAAE17;}
    .f19-st9{opacity:0.5;fill:#FAAE17;}
    .f19-st10{fill:#F37621;}
    .f19-st11{fill:#FFE2BD;}
    .f19-st12{fill:#FFF6EB;}
    .f19-st13{fill:#E0B796;}
    .f19-st14{fill:#BF7A46;}
    .f19-st15{fill:#31225E;}
    .f19-st16{fill:#291F57;}
    .f19-st17{fill:#1C1646;}
    .f19-st18{fill:#1A1A1A;}
    .f19-st19{fill:#666C6D;}
    .f19-st20{fill:#F7BC8A;}
    .f19-st21{fill:#B7A1CD;}
    .f19-st22{fill:#E9B085;}
    .f19-st23{fill:#F1B486;}
    .f19-st24{fill:#7D5DA6;}
    .f19-st25{fill:#7660A8;}
    .f19-st26{fill:#F27B8F;}
    .f19-st27{fill:#AE8075;}
    .f19-st28{fill:#121034;}
    .f19-st29{fill:#FCFDFF;}
    .f19-st30{fill:#CCDCE6;}
    .f19-st31{fill:#DC3F70;}
    .f19-st32{fill:#AAABAB;}
    .f19-st33{fill:#D1D1D3;}
    .f19-st34{fill:#905D32;}
    .f19-st35{fill:#979797;}
    .f19-st36{fill:#333333;}
    .f19-st37{fill:#090607;}
    .f19-st38{fill:#190E44;}
    .f19-st39{fill:#8D8C8D;}
    .f19-st20b{fill:#8B654A;}
    .f19-st20c{fill:#d09165;}
    .f19-st22c{fill:#a77756;}