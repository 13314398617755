#section-03 {
    position: relative;
    @include responsive-property(margin-bottom, 33.33333vh, 0vh, 'all');
    .anchor {
        position: absolute;
        top: 50%;
        transform: translateY(-50vh);
    }
    .video-container {
        position: absolute;
        width: 66.66667%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include responsive-property(width, 90%, 66.66667%);
    }
}