#section-01 {
    svg {
        opacity: 1;
        transition: opacity ($speed * 2);
    }
    &.section-fade {
        svg {
            opacity: 0;
        }
    }
}
#intro {
    position: relative;
    transform: translateY(33.33333vh);
}
#intro-end {
    position: relative;
    transform: translateY(-100vw);
}