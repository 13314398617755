    .the-stat-st0{fill:url(#the-stat-blob_1_);}
    .the-stat-st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F2F2F2;}
    .the-stat-st2{fill:#FFC226;}
    .the-stat-st3{fill:#133155;}
    .the-stat-st4{fill:#F7A391;}
    .the-stat-st5{fill:#F48561;}
    .the-stat-st6{fill:#753F1A;}
    .the-stat-st7{fill:#282827;}
    .the-stat-st8{fill:#B4805D;}
    .the-stat-st9{fill:none;stroke:#133155;stroke-width:2.64;stroke-linecap:round;stroke-miterlimit:10;}
    .the-stat-st10{fill:#EEAC7F;}
    .the-stat-st11{fill:#0B4076;}
    .the-stat-st12{fill:#0E4C87;}
    .the-stat-st13{fill:#808283;}
    .the-stat-st14{fill:#462F8B;}
    .the-stat-st15{fill:#9A9C9F;}
    .the-stat-st16{fill:#CF9169;}
    .the-stat-st17{fill:#9CA148;}
    .the-stat-st18{fill-rule:evenodd;clip-rule:evenodd;fill:#133155;}
    .the-stat-st19{fill:#496CA7;}
    .the-stat-st20{fill:#8E5A3D;}