html,body {
    padding: 0;
    margin: 0;
}

body {
    min-width: 300px;
}

body.loaded {
    visibility: visible;
}

img {
    max-width: 100%;
    height: auto;
}

.read-more {
    position: relative;
}

.read-more-content-container {
    position: relative;
    overflow-y: hidden;
    transition: height ($speed * 2);
    opacity: 0;
    .read-more-content {
        padding: 1rem 0;
    }
}

.section-active {
    .read-more-content-container {
        opacity: 1;
    }
}

.alignfull {
    @include alignfull();
}

.iframe-container {
    margin: 2em 0;
}

.section-container {
    width: 100vw;
    overflow: hidden;
    // padding-bottom: 100vh;
}