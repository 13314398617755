// responsive-property

@mixin responsive-property( $property, $min-size, $max-size, $breakpoints: 'grid' ) {
    // pass all as last parameter to use $all-breakpoints
    $responsive-breakpoints: $grid-breakpoints;
    @if $breakpoints == all {
        $responsive-breakpoints: $all-breakpoints;
    }
    // increment property for each breakpoint
    $i: 0;
    $count: length($responsive-breakpoints) - 1;
    @each $name, $width in $responsive-breakpoints {
        @if $width > 0 {
            @media (min-width: $width) {
                $increment: ($max-size - $min-size) / $count;
                $this-size: $min-size + ( $increment * $i );
                #{$property}: $this-size;
            }
        } @else {
            #{$property}: $min-size;
        }
        $i: $i + 1;
    }
}

// responsive-properties

@mixin responsive-properties( $parameters... ) {
    // pass all as first parameter to use $all-breakpoints
    $responsive-breakpoints: $grid-breakpoints;
    $properties: $parameters;
    @if type-of(nth($parameters, 1)) != 'list' {
        @if nth($parameters, 1) == all {
            $responsive-breakpoints: $all-breakpoints;
        }
        $properties: ();
        @for $i from 2 through length($parameters) {
            $properties: append($properties, nth($parameters, $i));
        }
    }
    // increment property for each breakpoint
    $i: 0;
    $count: length($responsive-breakpoints) - 1;
    @each $name, $width in $responsive-breakpoints {
        @if $width > 0 {
            @media (min-width: $width) {
                @each $property, $min-size, $max-size in $properties {
                    $increment: ($max-size - $min-size) / $count;
                    $this-size: $min-size + ( $increment * $i );
                    #{$property}: $this-size;
                }
            }
        } @else {
            @each $property, $min-size, $max-size in $properties {
                #{$property}: $min-size;
            }
        }
        $i: $i + 1;
    }
}

// alignfull

@mixin alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
}

// container-max-widths

@mixin container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

// ie11-hack

@mixin ie11-hack() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}