// fonts

@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,400&family=Poppins:wght@400;500;600;700&display=swap);

$font-sans: 'Poppins', sans-serif;
$font-serif: 'Playfair Display', serif;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 900;

// brand colors
$blaze: #ffc20e; // yellow 
$cobalt: #00427a; // blue // rgb(0,66,122)
$crimson: #c61d23; // red
$laurel: #a3a510; // green
$amethyst: #652d89; // purple
$thunderwolf: #a7a9ac; // grey

// additional colors

$teal: #50bfa5;
$teal-dark: #3DA48C;
$peach: #f49e80;
$peach-dark: #f47458;
$black: #000000;
$darker-grey: #333333;
$dark-grey: #666666;
$light-grey: #cccccc;
$white: #ffffff;

// text colors

$text-color: $darker-grey;
$link-color: $cobalt;

// fontawesome

$fa: 'Font Awesome 5 Pro';
$fa-light-weight: 300;
$fa-regular-weight: 400;
$fa-solid-weight: 900;

$fa-brands: 'Font Awesome 5 Brands';
$fa-brands-weight: 400;

$fa-duotone: 'Font Awesome 5 Duotone';
$fa-duotone-weight: 900;

// interface variables

$speed: 0.4s;
$min-width: 280px;
$y-margin: 3em;
$menu-width: 600px;

// fontawesome

$fa-font-path: '../lib/fontawesome/webfonts';

// bootstrap

$grid-columns: 24;
$grid-gutter-width: 3em;
$grid-gutter-width-root: 4rem;

// breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$x2: 1440px;
$x3: 1680px;
$x4: 1920px;
$x5: 2160px;
$x6: 2400px;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    x2: 1380px,
    x3: 1600px,
    // x4: 1840px,
    // x5: 2060px,
    // x6: 2300px
);

$grid-breakpoints: (
    xs: 0,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    x2: $x2,
    x3: $x3,
    // x4: $x4,
    // x5: $x5,
    // x6: $x6
);

$all-breakpoints: (
    xs: 0,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    x2: $x2,
    x3: $x3,
    x4: $x4,
    x5: $x5,
    x6: $x6,
);

$spacer: 0.5rem;

$spacers: (
    0: 0,
    1: ($spacer * 1),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    6: ($spacer * 6),
    7: ($spacer * 7),
    8: ($spacer * 8),
    9: ($spacer * 9),
    10: ($spacer * 10),
    11: ($spacer * 11),
    12: ($spacer * 12)
);
