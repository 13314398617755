    .f21-st0{fill:#BDD1F9;}
    .f21-st1{fill:#91B5F8;}
    .f21-st2{fill:#FFFFFF;}
    .f21-st3{fill:#C8DAFC;}
    .f21-st4{fill:#2B3AC9;}
    .f21-st5{fill:#1F1B28;}
    .f21-st6{fill:#7C9AF2;}
    .f21-st7{fill:#6A86CE;}
    .f21-st8{fill:#17152B;}
    .f21-st9{fill:#050521;}
    .f21-st10{fill:#6A84CC;}
    .f21-st11{fill:#4851EA;}
    .f21-st12{fill:#060633;}
    .f21-st13{fill:#F87267;}
    .f21-st14{fill:#050356;}
    .f21-st15{fill:#152793;}
    .f21-st16{fill:#5C86D1;}
    .f21-st17{fill:#FF9391;}
    .f21-st18{fill:#FF7367;}
    .f21-st19{fill:#FF6868;}
    .f21-st20{fill:#FF564F;}
    .f21-st21{fill:#020231;}
    .f21-st22{fill:#FA7267;}
    .f21-st23{fill:#BC8158;}
    .f21-st24{fill:#6EA1AE;}
    .f21-st25{fill:#71A9CD;}
    .f21-st26{opacity:0.6;fill:#FFFFFF;}
    .f21-st27{fill:#0E4C87;}
    .f21-st28{fill:#C52127;}
    .f21-st29{fill:#FFC114;}