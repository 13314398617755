#section-15 {
    position: relative;
    width: 100%;
    .anchor {
        position: absolute;
        top: 50%;
        transform: translateY(-50vh);
    }
    #section-15-trigger {
        position: absolute;
        top: 50%;
        transform: translateY(-50vh);
    }
    .intro-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        h1 {
            color: $cobalt;
            @include responsive-property(margin-top, 12vw, 20vw, 'all');
        }
    }
    .stat-content-container {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        .stat-content-col {
            @include make-col-ready();
            @include make-col(11);
            padding-left: 1rem;
            padding-right: 1rem;
            @include media-breakpoint-up(sm) {
                padding-left: $grid-gutter-width / 2;
                padding-right: $grid-gutter-width / 2;
            }
            @include media-breakpoint-up(md) {
                @include make-col-offset(1);
            }
            @include media-breakpoint-up(lg) {
                @include make-col(10);
            }
            @include media-breakpoint-up(x2) {
                @include make-col(9);
            }
            @media (min-width: $x4) {
                @include make-col(8);
            }
            @media (min-width: $x6) {
                @include make-col(7);
            }
            .stat-content {
                color: $cobalt;
                .stat-italic-sm {
                    font-family: $font-serif;
                    font-weight: $weight-regular;
                    font-style: italic;
                    line-height: 1.5;
                    @include responsive-property(font-size, 1em, 1.33333em);
                }
                .stat-md,
                .stat-lg,
                .stat-xl {
                    font-family: $font-serif;
                    font-weight: $weight-black;
                }
                .stat-md {
                    @include responsive-properties('all',
                        (font-size, 1.5em, 4em),
                        (line-height, 1.3, 1.1)
                    );
                }
                .stat-lg {
                    @include responsive-properties('all',
                        (font-size, 2em, 7em),
                        (line-height, 1.2, 1)
                    );
                }
                .stat-xl {
                    @include responsive-properties('all',
                        (font-size, 3em, 12em),
                        (line-height, 1, 1)
                    );
                }
                .stat-btn-container {
                    @include responsive-property(margin-top, 1em, 3em);
                    a.stat-btn {
                        display: inline-block;
                        text-decoration: none;
                        font-family: $font-sans;
                        font-weight: $weight-semibold;
                        text-transform: uppercase;
                        letter-spacing: 0.15em;
                        padding: 0.6em 1em 0.5em 1.5em;
                        background-color: rgba(255, 255, 255, 0.8);
                        color: $cobalt;
                        border-radius: 3em;
                        border: 3px solid $cobalt;
                        @include responsive-property(border-width, 2px, 3px);
                        &:hover {
                            text-decoration: none;
                            background-color: $cobalt;
                            color: $white;
                        }
                    }
                }
            }
            &.col-right {
                @include make-col-offset(12);
                @include media-breakpoint-up(md) {
                    @include make-col-offset(13);
                }
            }
            &.col-white {
                .stat-content {
                    color: $white;
                }
            }
        }
    }
}