    .the3-st0{fill:url(#the3-blob_1);}
    .the3-st1{fill:#FFFFFF;}
    .the3-st2{fill:#F2F2F2;}
    .the3-st3{fill:#FFC226;}
    .the3-st4{fill:#133155;}
    .the3-st5{fill:#F7A391;}
    .the3-st6{fill:#F48561;}
    .the3-st7{fill:#753F1A;}
    .the3-st8{fill:#282827;}
    .the3-st9{fill:#B4805D;}
    .the3-st10{fill:none;stroke:#133155;stroke-width:2.64;stroke-linecap:round;stroke-miterlimit:10;}
    .the3-st11{fill:#EEAC7F;}
    .the3-st12{fill:#0B4076;}
    .the3-st13{fill:#0E4C87;}
    .the3-st14{fill:#808283;}
    .the3-st15{fill:#462F8B;}
    .the3-st16{fill:#9A9C9F;}
    .the3-st17{fill:#CF9169;}
    .the3-st18{fill:#9CA148;}
    .the3-st19{fill:#133155;}
    .the3-st20{fill:#496CA7;}
    .the3-st21{fill:#8E5A3D;}