// modal__factoid

.modal__factoid {
    @include responsive-property(max-width, 95vw, 66.66667vw);
    .modal__inner {
        max-height: calc(95vh - 4em);
        [class*='col'] {
            padding-left: 0;
            padding-right: 0;
            @include media-breakpoint-up(md) {
                padding-left: $grid-gutter-width / 2;
                padding-right: $grid-gutter-width / 2;
            }
        }
        .modal__title {
            margin-top: 0;
            margin-bottom: 0;
            color: $cobalt;
            box-sizing: border-box;
        }
        .modal__content {
            margin-top: 1.5rem;
            color: rgba(0, 0, 0, 0.8);
            img {
                border-radius: 0 2em 0 2em;
                margin-bottom: 1.5rem;
            }
            p:first-child {
                margin-top: 0;
            }
        }
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 4em;
        background-image: url(../svg/modal-top.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}