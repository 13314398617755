html {
    font-size: 1rem;
    @include responsive-property(font-size, 0.875rem, 1.25rem);
}

body {
    font-family: $font-sans;
    font-weight: $weight-regular;
    color: $text-color;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

a {
    color: $link-color;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    img {
        border: name;
    }
}

h1 {
    font-family: $font-serif;
    font-weight: $weight-black;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 2em, 6em),
        (line-height, 1.2, 1)
    );
}

h2 {
    font-family: $font-serif;
    font-weight: $weight-bold;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 1.5em, 4em),
        (line-height, 1.3, 1.1)
    );
}

h3 {
    font-family: $font-serif;
    font-weight: $weight-bold;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 1.25em, 3.5em),
        (line-height, 1.4, 1.1)
    );
}

h4 {
    font-family: $font-sans;
    font-weight: $weight-bold;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 1.2em, 2.5em),
        (line-height, 1.4, 1.1)
    );
}

h5 {
    font-family: $font-sans;
    font-weight: $weight-bold;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 1.15em, 2em),
        (line-height, 1.4, 1.1)
    );
}

h6 {
    font-family: $font-sans;
    font-weight: $weight-bold;
    margin: 1rem 0;
    @include responsive-properties('all',
        (font-size, 1.1em, 1.5em),
        (line-height, 1.4, 1.1)
    );
}

p {
    margin: 1rem 0;
}

sup {
    font-size: 0.5em;
}

.lead {
    @include responsive-property(font-size, 1em, 1.2em);
}

blockquote {
    font-family: $font-serif;
    font-style: italic;
    color: $cobalt;
    margin: 2.5rem 0;
    @include responsive-property(font-size, 1em, 1.5em);
    display: flex;
    justify-content: center;
    .quote-mark {
        font-family: $font-serif;
        font-style: normal;
        font-size: 5em;
        color: $amethyst;
        margin-right: 0.5rem;
        @include responsive-property(line-height, 3rem, 5rem);
    }
}

// text color

.text-blaze {
    color: $blaze;
}
.text-cobalt {
    color: $cobalt;
}
.text-crimson {
    color: $crimson;
}
.text-laurel {
    color: $laurel;
}
.text-amethyst {
    color: $amethyst;
}
.text-thunderwolf {
    color: $thunderwolf;
}
.text-white {
    color: $white;
}
.text-black {
    color: $black;
}
.text-teal {
    color: $teal-dark;
}

// text

.text-nowrap {
    white-space: nowrap;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-regular {
    text-transform: none;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-small {
    font-size: 0.8em;
}
.text-75 {
    font-size: 0.75em;
}
.text-66 {
    font-size: 0.66667em;
}
.text-50 {
    font-size: 0.5em;
}
.text-33 {
    font-size: 0.333333em;
}
.text-25 {
    font-size: 0.25em;
}
.text-dollar {
    display: inline-block;
    font-size: 0.5em;
    vertical-align: middle;
}

// heading

.heading-italic-top {
    display: block;
    font-family: $font-serif;
    font-weight: $weight-regular;
    font-style: italic;
    font-size: 0.4em;
    line-height: 1.5;
    @include responsive-property(font-size, 0.5em, 0.4em);
}

.heading-sans-top,
.heading-sans-btm {
    display: block;
    font-family: $font-sans;
    font-weight: $weight-bold;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 1.5;
    @include responsive-property(font-size, 0.33333em, 0.3em);
}

.heading-sans-btm {
    margin-top: 0.5em;
}

// hr

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $black;
    margin: 3rem 0;
}