    .f16-st0{fill:url(#f16-blob_1_);}
    .f16-st1{fill:#7DA4CE;}
    .f16-st2{fill:#E3F0FB;}
    .f16-st3{fill:#E7EFF9;}
    .f16-st4{fill:#BED9E7;}
    .f16-st5{fill:#517FB5;}
    .f16-st6{opacity:0.16;fill:url(#f16-XMLID_2_);}
    .f16-st7{fill:#FFFFFF;}
    .f16-st8{fill:#2A3444;}
    .f16-st9{fill:#FF5D5B;}
    .f16-st10{fill:url(#f16-XMLID_3_);}
    .f16-st11{fill:#F49E80;}
    .f16-st12{fill:#004584;}
    .f16-st13{fill:#313C6F;}
    .f16-st14{fill:#010C4F;}
    .f16-st15{fill:#001A3F;}
    .f16-st16{fill:#F09473;}
    .f16-st17{fill:url(#f16-XMLID_4_);}
    .f16-st18{fill:url(#f16-XMLID_6_);}
    .f16-st19{fill:#001336;}
    .f16-st20{opacity:0.44;}
    .f16-st21{fill:url(#f16-XMLID_7_);}
    .f16-st22{fill:#FF9E7B;}
    .f16-st23{fill:url(#f16-XMLID_8_);}
    .f16-st24{fill:#472906;}
    .f16-st25{fill:#4F6CE5;}
    .f16-st26{fill:#6D82FF;}
    .f16-st27{fill:#232323;}
    .f16-st28{fill:url(#f16-XMLID_9_);}
    .f16-st29{fill:#D3B41E;}
    .f16-st30{fill:#1C110B;}
    .f16-st31{fill:#561F00;}
    .f16-st32{fill:#6B2900;}
    .f16-st33{fill:url(#f16-XMLID_10_);}
    .f16-st34{fill:url(#f16-XMLID_11_);}
    .f16-st35{fill:url(#f16-XMLID_12_);}
    .f16-st36{fill:url(#f16-XMLID_13_);}
    .f16-st37{fill:url(#f16-XMLID_14_);}
    .f16-st38{fill:url(#f16-XMLID_15_);}
    .f16-st39{fill:url(#f16-XMLID_16_);}
    .f16-st40{fill:#00A872;}
    .f16-st41{fill:url(#f16-XMLID_17_);}
    .f16-st42{fill:#4B5CD7;}
    .f16-st43{fill:#00C983;}
    .f16-st44{fill:#FF9200;}
    .f16-st45{fill:#00E294;}
    .f16-st46{opacity:0.3;}
    .f16-st47{fill:#FFC150;}
    .f16-st48{fill:url(#f16-XMLID_18_);}
    .f16-st49{opacity:0.61;fill:#D0DFFF;}
    .f16-st50{fill:url(#f16-XMLID_19_);}
    .f16-st51{fill:#9E4B10;}
    .f16-st52{opacity:0.68;fill:#9E4B10;}
    .f16-st53{fill:#FFA986;}
    .f16-st54{fill:#6EA1AE;}
    .f16-st55{fill:#71A9CD;}
    .f16-st56{opacity:0.6;fill:#FFFFFF;}
    .f16-st57{fill:url(#f16-XMLID_22_);}
    .f16-st58{fill:url(#f16-XMLID_23_);}
    .f16-st59{fill:url(#f16-XMLID_25_);}
    .f16-st60{fill:url(#f16-XMLID_26_);}
    .f16-st61{fill:url(#f16-XMLID_27_);}
    .f16-st62{opacity:0.2;fill:#4B3B68;}
    .f16-st63{fill:#685E70;}
    .f16-st64{fill:#FCB080;}
    .f16-st65{opacity:0.47;fill:#A9A9A9;}
    .f16-st66{fill:url(#f16-XMLID_28_);}
    .f16-st67{fill:#FF5C5B;}
    .f16-st68{opacity:0.86;fill:#FF5C5B;}
    .f16-st69{opacity:0.68;fill:url(#f16-XMLID_29_);}
    .f16-st70{fill:#B1D0FF;}
    .f16-st71{fill:#E9EEF8;}
    .f16-st72{fill:url(#f16-XMLID_30_);}
    .f16-st73{fill:#FFB294;}
    .f16-st74{fill:#FF5B5E;}
    .f16-st75{fill:#A07B40;}
    .f16-st76{opacity:0.65;}
    .f16-st77{fill:#2E1C14;}
    .f16-st78{opacity:0.65;fill:#2E1C14;}
    .f16-st79{fill:url(#f16-XMLID_31_);}
    .f16-st80{fill:#654e28;}
    .f16-st81{fill:url(#f16-XMLID_32_);}
    .f16-st82{fill:#A4C5FF;}
    .f16-st83{fill:url(#f16-XMLID_33_);}
    .f16-st84{fill:url(#f16-XMLID_34_);}
    .f16-st85{fill:url(#f16-XMLID_35_);}
    .f16-st86{fill:url(#f16-XMLID_36_);}
    .f16-st87{fill:url(#f16-XMLID_37_);}
    .f16-st88{fill:url(#f16-XMLID_38_);}
    .f16-st89{fill:#CFE6F7;}
    .f16-st90{fill:url(#f16-XMLID_39_);}
    .f16-st91{fill:url(#f16-XMLID_40_);}
    .f16-st92{fill:url(#f16-XMLID_41_);}