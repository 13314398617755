    .the2-st0{fill:#F4AB5A;}
    .the2-st1{fill:#CED7ED;}
    .the2-st2{fill:#889CC8;}
    .the2-st3{fill:#F0893B;}
    .the2-st4{opacity:0.3;}
    .the2-st5{fill:#F5BB80;}
    .the2-st6{fill:#5676A7;}
    .the2-st7{opacity:0.2;}
    .the2-st8{fill:#9AB6DE;}
    .the2-st9{opacity:0.5;}
    .the2-st10{fill:#FFFFFF;}
    .the2-st11{fill:#29A9E1;}
    .the2-st12{fill:#27A1D5;}
    .the2-st13{fill:#FAB56F;}
    .the2-st14{fill:#34142C;}
    .the2-st15{fill:#FCC791;}
    .the2-st16{fill:#BE4347;}
    .the2-st17{fill:#FFE5CE;}
    .the2-st18{fill:#1989BC;}
    .the2-st19{fill:#F6F7F7;}
    .the2-st20{fill:#E6F5FC;}
    .the2-st21{fill:#D9F0FC;}
    .the2-st22{fill:#9CA0AA;}
    .the2-st23{fill:#F19E5E;}
    .the2-st24{fill:#CF7732;}
    .the2-st25{fill:#BEC9E5;}
    .the2-st26{fill:#7388BC;}
    .the2-st27{fill:#8FA5D5;}
    .the2-st28{fill:#869AC6;}
    .the2-st29{fill:#606D90;}
    .the2-st30{fill:#68779D;}
    .the2-st31{fill:#606E90;}
    .the2-st32{fill:#627093;}
    .the2-st33{fill:#29A9E1;stroke:#7C7B7A;stroke-width:40;stroke-miterlimit:10;}
    .the2-st34{fill:#B3D7F1;}
    .the2-st35{fill:#DBECF9;}
    .the2-st36{fill:#C8E3F6;}
    .the2-st37{fill:#5292CE;}
    .the2-st38{fill:#1F2C45;}
    .the2-st39{fill:#A07044;}
    .the2-st40{fill:#293478;}
    .the2-st41{fill:#24324D;}
    .the2-st42{fill:#2F405E;}
    .the2-st43{fill:#F69220;}
    .the2-st44{fill:#1A509E;}
    .the2-st45{fill:#E7F2F9;}
    .the2-st46{fill:#2A3852;}
    .the2-st47{fill:#AC702D;}
    .the2-st48{fill:#DF4F54;}
    .the2-st49{fill:#BE864E;}
    .the2-st50{fill:#8FB8D0;}
    .the2-st51{fill:#AA3338;}
    .the2-st52{fill:#C13C3F;}
    .the2-st53{fill:#FFEFDE;}
    .the2-st54{fill:#00B4E6;}
    .the2-st55{fill:#31C4F3;}
    .the2-st56{fill:#3C51A2;}
    .the2-st57{fill:#283B8C;}
    .the2-st58{fill:#3BC5F3;}
    .the2-st59{fill:#DE8F32;}
    .the2-st60{fill:#F79F39;}
    .the2-st61{fill:#CBCBCA;}
    .the2-st62{fill:#A9CFE4;}
    .the2-st63{fill:#2A3254;}
    .the2-st64{fill:#8E96BB;}
    .the2-st65{fill:#8692C8;}
    .the2-st66{fill:#ACB6DC;}
    .the2-st67{fill:#222842;}
    .the2-st68{fill:#38406B;}
    .the2-st69{fill:#B8BFE0;}
    .the2-st70{fill:#FFEAC8;}
    .the2-st71{fill:#DC8035;}