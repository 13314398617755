$colnum-sm: 8;
$colnum-sm-1: $colnum-sm - 1;
$colnum-lg: 15;
$colnum-lg-1: $colnum-lg - 1;

.nav-container {
    position: fixed;
    display: block;
    z-index: 50;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    nav {
        position: relative;
        display: inline-block;
        ul {
            list-style: none;
            margin: 0 0.375em 0.75em;
            padding: 0 0.75em;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 3em;
            li {
                padding: 0.375em;
                flex: 0 0 calc(100% / #{$colnum-sm});
                max-width: calc(100% / #{$colnum-sm});
                text-align: center;
                a {
                    display: inline-block;
                    position: relative;
                    .link-name {
                        display: block;
                        position: absolute;
                        white-space: nowrap;
                        top: 0;
                        left: 50%;
                        pointer-events: none;
                        color: $cobalt;
                        background-color: $cobalt;
                        color: $white;
                        $font-weight: $weight-medium;
                        border-radius: 1.5em;
                        padding: 0.375em 1.5em;
                        transform: translate(-50%, calc(-100% - 0.375em));
                        opacity: 0;
                        transition: opacity $speed;
                    }
                    &:hover {
                        .link-name {
                            opacity: 1;
                        }
                        .fa-circle {
                            &:before {
                                content: '\f192';
                            }
                        }
                    }
                    &.link-active {
                        .fa-circle {
                            &:before {
                                content: '\f111';
                                font-weight: $fa-solid-weight;
                            }
                        }
                    }
                }
                &:nth-child(#{$colnum-sm}n+1) {
                    a {
                        .link-name {
                            transform: translate(-0.75em, calc(-100% - 0.375em));
                        }
                    }
                }
                &:nth-child(#{$colnum-sm}n+2) {
                    a {
                        .link-name {
                            transform: translate(-3em, calc(-100% - 0.375em));
                        }
                    }
                }
                &:nth-child(#{$colnum-sm}n+#{$colnum-sm-1}) {
                    a {
                        .link-name {
                            transform: translate(calc(-100% + 3em), calc(-100% - 0.375em));
                        }
                    }
                }
                &:nth-child(#{$colnum-sm}n+#{$colnum-sm}) {
                    a {
                        .link-name {
                            transform: translate(calc(-100% + 0.75em), calc(-100% - 0.375em));
                        }
                    }
                }
                @if $colnum-lg % 2 != 0 { // if odd number
                    &:nth-child(#{$colnum-lg-1}n) {
                        a {
                            .link-name {
                                transform: translate(calc(-100% + 3em), calc(-100% - 0.375em));
                            }
                        }
                    }
                    &:nth-child(#{$colnum-lg}n) {
                        a {
                            .link-name {
                                transform: translate(calc(-100% + 0.75em), calc(-100% - 0.375em));
                            }
                        }
                    }
                } // end if odd number
            }
        }
    }
    @include media-breakpoint-up(md) {
        width: auto;
        nav {
            width: auto;
            ul {
                flex-wrap: nowrap;
                margin: 0 0.75em 0.75em;
                li {
                    flex: 0 1 auto;
                    max-width: none;
                    padding: 0.75em;
                    &:nth-child(#{$colnum-sm}n+1),
                    &:nth-child(#{$colnum-sm}n+2),
                    &:nth-child(#{$colnum-sm}n+#{$colnum-sm-1}),
                    &:nth-child(#{$colnum-sm}n+#{$colnum-sm}) {
                        a {
                            .link-name {
                                transform: translate(-50%, calc(-100% - 0.375em));
                            }
                        }
                    }
                    &:nth-child(#{$colnum-lg}n+1) {
                        a {
                            .link-name {
                                transform: translate(-0.75em, calc(-100% - 0.375em));
                            }
                        }
                    }
                    &:nth-child(#{$colnum-lg}n+2) {
                        a {
                            .link-name {
                                transform: translate(-3em, calc(-100% - 0.375em));
                            }
                        }
                    }
                    &:nth-child(#{$colnum-lg}n+#{$colnum-lg-1}) {
                        a {
                            .link-name {
                                transform: translate(calc(-100% + 3em), calc(-100% - 0.375em));
                            }
                        }
                    }
                    &:nth-child(#{$colnum-lg}n+#{$colnum-lg}) {
                        a {
                            .link-name {
                                transform: translate(calc(-100% + 0.75em), calc(-100% - 0.375em));
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        nav {
            width: auto;
            ul {
                flex-wrap: nowrap;
                li {
                    &:nth-child(#{$colnum-lg}n+1),
                    &:nth-child(#{$colnum-lg}n+2),
                    &:nth-child(#{$colnum-lg}n+#{$colnum-lg-1}),
                    &:nth-child(#{$colnum-lg}n+#{$colnum-lg}) {
                        a {
                            .link-name {
                                transform: translate(-50%, calc(-100% - 0.375em));
                            }
                        }
                    }
                }
            }
        }
    }
}