	.f7-st0{fill:url(#f7-blob-back_1_);}
	.f7-st1{fill:#F47458;}
	.f7-st2{fill:#F79C8D;}
	.f7-st3{fill:#FFF3D9;}
	.f7-st4{fill:#FED333;}
	.f7-st5{fill:#F48C7F;}
	.f7-st6{fill:#F7CC7F;}
	.f7-st7{fill:#50BFA5;}
	.f7-st8{fill:#9BC1F9;}
	.f7-st9{fill:#FAE0B2;}
	.f7-st10{fill:#FFFFFF;}
	.f7-st11{fill:#96D9C9;}
	.f7-st12{fill:#EFEFEF;}
	.f7-st13{fill:#B75742;}
	.f7-st14{fill:#466EB6;}
	.f7-st15{fill:#86B1F2;}
	.f7-st16{fill:#355389;}
	.f7-st17{fill:#F7A491;}
	.f7-st18{fill:#184655;}
	.f7-st19{display:none;}
	.f7-st20{display:inline;fill:#F7A491;}
	.f7-st21{display:inline;fill:#184655;}
	.f7-st22{display:inline;fill:#B75742;}
	.f7-st23{display:none;fill:#355389;}
	.f7-st24{fill:#13375B;}
	.f7-st25{fill:#07345A;}
	.f7-st26{fill:#3C8F7C;}
	.f7-st27{fill:#F8A492;}
	// f7c
	.f7c-st0{fill:#355389;}
	.f7c-st1{fill:#466EB6;}
	.f7c-st2{fill:#13375B;}
	.f7c-st3{fill:#184655;}
	.f7c-st4{fill:#50BFA5;}
	.f7c-st5{fill:#FAE0B2;}
	.f7c-st6{fill:#B75742;}