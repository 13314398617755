.btn {
    display: inline-block;
    font-family: $font-sans;
    font-weight: $weight-bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    white-space: nowrap;
    padding: 0.5em 1.25em;
    border-radius: 1.5em;
    border: 2px solid $cobalt;
    color: $cobalt;
    background-color: transparent;
    transition: background-color $speed, color $speed;
    @include responsive-properties(
        (font-size, 0.66667em, 1em),
        (padding-left, 1.5em, 2em),
        (padding-right, 1em, 1.5em)
    );
    @include media-breakpoint-up(lg) {
        border-width: 3px;
    }
    &:hover {
        text-decoration: none;
        color: $white;
        background-color: $cobalt;
    }
    &.btn-white {
        color: $white;
        border-color: $white;
        &:hover {
            color: $cobalt;
            background-color: $white;
        }
    }
}