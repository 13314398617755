    .f15-st0{fill:url(#f15-blob_1_);}
    .f15-st1{fill:#82B378;}
    .f15-st2{fill:#233862;}
    .f15-st3{fill:#BC3F3F;}
    .f15-st4{fill:#A53D38;}
    .f15-st5{fill:#FFB27D;}
    .f15-st6{fill:#EF9E67;}
    .f15-st7{fill:#FFB93E;}
    .f15-st8{fill:#FFA412;}
    .f15-st9{fill:#2B478B;}
    .f15-st10{fill:#E58638;}
    .f15-st11{fill:#163560;}
    .f15-st12{fill:#466AA5;}
    .f15-st13{fill:#EE9849;}
    .f15-st14{fill:#D17A33;}
    .f15-st15{fill:#D2E4FF;}
    .f15-st16{fill:#912F2F;}
    .f15-st17{fill:#578E9B;}
    .f15-st18{fill:#74ACBE;}
    .f15-st19{fill:#4A75CB;}
    .f15-st20{fill:#ED985F;}
    .f15-st21{fill:#2C3A64;}
    .f15-st22{fill:#739AF0;}
    .f15-st23{fill:#BBD4FF;}
    .f15-st24{fill:#678FE5;}
    .f15-st25{fill:#5FABB7;}
    .f15-st26{fill:#C45446;}
    .f15-st27{fill:#2E3552;}
    .f15-st5b{fill:#af6e52;}
    .f15-st20b{fill:#7c4f3c;}
    .f15-st4c{fill:#974c32;}
    .f15-st5c{fill:#d09165;}
    .f15-st6c{fill:#b07c57;}