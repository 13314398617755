svg {
    display: block;
}

.svg-frame {
    width: 100vw;
    height: 100vh;
    overflow: visible;
    min-width: 300px;
    @include responsive-property(min-height, 160px, 1280px, 'all');
}

.svg-frame-background {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    overflow: visible;
    min-width: 300px;
}