    .f4-st0 {
        fill: #0051EC;
    }
    
    .f4-st1 {
        fill: #7A64C5;
    }
    
    .f4-st2 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: url(#f4-sky_2_);
    }
    
    .f4-st3 {
        fill: url(#f4-sunrise-sky_2_);
    }
    
    .f4-st4 {
        fill: #3F7BFF;
    }
    
    .f4-st5 {
        fill: #E07538;
    }
    
    .f4-st6 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #99A3FB;
    }
    
    .f4-st7 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #E07538;
    }
    
    .f4-st8 {
        opacity: 0.2578;
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: url(#f4-fog-1_1_);
        enable-background: new;
    }
    
    .f4-st9 {
        opacity: 0.2565;
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: url(#f4-fog-2_1_);
        enable-background: new;
    }
    
    .f4-st10 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #FFFFFF;
    }
    
    .f4-st11 {
        fill: url(#f4-XMLID_5_);
    }
    
    .f4-st12 {
        fill: url(#f4-XMLID_7_);
    }
    
    .f4-st13 {
        fill: url(#f4-XMLID_8_);
    }
    
    .f4-st14 {
        fill: url(#f4-XMLID_10_);
    }
    
    .f4-st15 {
        fill: url(#f4-XMLID_12_);
    }
    
    .f4-st16 {
        fill: url(#f4-XMLID_13_);
    }
    
    .f4-st17 {
        fill: url(#f4-XMLID_24_);
    }
    
    .f4-st18 {
        fill: url(#f4-XMLID_25_);
    }
    
    .f4-st19 {
        fill: url(#f4-XMLID_26_);
    }
    
    .f4-st20 {
        fill: url(#f4-XMLID_27_);
    }
    
    .f4-st21 {
        fill: #F68D4E;
    }
    
    .f4-st22 {
        fill: #DD938A;
    }
    
    .f4-st23 {
        fill: url(#f4-waves_2_);
    }
    
    .f4-st24 {
        fill: url(#f4-sunrise-waves_2_);
    }
    
    .f4-st25 {
        fill: url(#f4-sunrise-glow_1_);
    }
    
    .f4-st26 {
        fill: #FFFFAE;
    }
    
    .f4-st27 {
        display: none;
    }
    
    .f4-st28 {
        display: inline;
        fill: #FFFFFF;
    }
    
    .f4-st29 {
        display: none;
        fill: #C52127;
    }
    
    .f4-st30 {
        display: none;
        fill: #FFC114;
    }
    
    .f4-st31 {
        fill: #FFFF63;
    }
    
    .f4-st32 {
        display: none;
        fill: #0E4C87;
    }
    
    .f4-st33 {
        display: none;
        fill: #FFFFFF;
    }
    
    .f4-st34 {
        fill: url(#f4-water_1_);
    }
    
    .f4-st35 {
        fill: url(#f4-sunrise-water_1_);
    }
    
    .f4-st36 {
        fill: #FFFFFF;
    }
    
    .f4-st37 {
        fill: url(#f4-XMLID_28_);
    }
    
    .f4-st38 {
        fill: url(#f4-XMLID_29_);
    }
    
    .f4-st39 {
        fill: url(#f4-XMLID_30_);
    }
    
    .f4-st40 {
        fill: url(#f4-XMLID_31_);
    }
    
    .f4-st41 {
        fill: url(#f4-XMLID_41_);
    }
    
    .f4-st42 {
        fill: url(#f4-XMLID_43_);
    }
    
    .f4-st43 {
        fill: url(#f4-XMLID_44_);
    }
    
    .f4-st44 {
        fill: url(#f4-XMLID_45_);
    }
    
    .f4-st45 {
        fill: url(#f4-XMLID_46_);
    }
    
    .f4-st46 {
        fill: url(#f4-XMLID_47_);
    }
    
    .f4-st47 {
        fill: url(#f4-XMLID_48_);
    }
    
    .f4-st48 {
        fill: url(#f4-XMLID_49_);
    }
    
    .f4-st49 {
        fill: url(#f4-XMLID_50_);
    }
    
    .f4-st50 {
        fill: url(#f4-XMLID_51_);
    }
    
    .f4-st51 {
        fill: url(#f4-XMLID_52_);
    }
    
    .f4-st52 {
        fill: url(#f4-XMLID_53_);
    }
    
    .f4-st53 {
        fill: url(#f4-XMLID_54_);
    }
    
    .f4-st54 {
        fill: url(#f4-XMLID_55_);
    }
    
    .f4-st55 {
        fill: url(#f4-XMLID_56_);
    }
    
    .f4-st56 {
        fill: url(#f4-XMLID_57_);
    }
    
    .f4-st57 {
        fill: url(#f4-XMLID_58_);
    }
    
    .f4-st58 {
        fill: url(#f4-XMLID_59_);
    }
    
    .f4-st59 {
        fill: url(#f4-XMLID_60_);
    }
    
    .f4-st60 {
        fill: url(#f4-XMLID_61_);
    }
    
    .f4-st61 {
        fill: url(#f4-XMLID_63_);
    }
    
    .f4-st62 {
        fill: url(#f4-XMLID_64_);
    }
    
    .f4-st63 {
        fill: url(#f4-XMLID_65_);
    }
    
    .f4-st64 {
        fill: url(#f4-XMLID_66_);
    }
    
    .f4-st65 {
        fill: url(#f4-XMLID_67_);
    }
    
    .f4-st66 {
        fill: url(#f4-XMLID_68_);
    }
    
    .f4-st67 {
        fill: url(#f4-XMLID_69_);
    }
    
    .f4-st68 {
        fill: url(#f4-XMLID_70_);
    }
    
    .f4-st69 {
        fill: url(#f4-XMLID_71_);
    }
    
    .f4-st70 {
        fill: url(#f4-XMLID_72_);
    }
    
    .f4-st71 {
        fill: url(#f4-XMLID_73_);
    }
    
    .f4-st72 {
        fill: url(#f4-XMLID_75_);
    }
    
    .f4-st73 {
        fill: url(#f4-XMLID_76_);
    }
    
    .f4-st74 {
        fill: url(#f4-XMLID_77_);
    }
    
    .f4-st75 {
        fill: url(#f4-XMLID_78_);
    }
    
    .f4-st76 {
        fill: url(#f4-XMLID_79_);
    }
    
    .f4-st77 {
        fill: url(#f4-XMLID_80_);
    }
    
    .f4-st78 {
        fill: url(#f4-XMLID_81_);
    }
    
    .f4-st79 {
        fill: url(#f4-XMLID_82_);
    }
    
    .f4-st80 {
        fill: url(#f4-XMLID_83_);
    }
    
    .f4-st81 {
        fill: url(#f4-XMLID_84_);
    }
    
    .f4-st82 {
        fill: url(#f4-XMLID_85_);
    }
    
    .f4-st83 {
        fill: url(#f4-XMLID_86_);
    }
    
    .f4-st84 {
        fill: url(#f4-XMLID_87_);
    }
    
    .f4-st85 {
        fill: url(#f4-XMLID_97_);
    }
    
    .f4-st86 {
        fill: url(#f4-XMLID_98_);
    }
    
    .f4-st87 {
        fill: url(#f4-XMLID_99_);
    }
    
    .f4-st88 {
        fill: url(#f4-XMLID_100_);
    }
    
    .f4-st89 {
        fill: url(#f4-XMLID_101_);
    }
    
    .f4-st90 {
        fill: url(#f4-XMLID_102_);
    }
    
    .f4-st91 {
        fill: url(#f4-XMLID_103_);
    }
    
    .f4-st92 {
        fill: url(#f4-XMLID_104_);
    }
    
    .f4-st93 {
        fill: url(#f4-XMLID_105_);
    }
    
    .f4-st94 {
        fill: url(#f4-XMLID_106_);
    }
    
    .f4-st95 {
        fill: url(#f4-XMLID_107_);
    }
    
    .f4-st96 {
        fill: url(#f4-XMLID_108_);
    }
    
    .f4-st97 {
        fill: url(#f4-XMLID_109_);
    }
    
    .f4-st98 {
        fill: url(#f4-XMLID_110_);
    }
    
    .f4-st99 {
        fill: url(#f4-XMLID_111_);
    }
    
    .f4-st100 {
        fill: url(#f4-XMLID_112_);
    }
    
    .f4-st101 {
        fill: url(#f4-XMLID_113_);
    }
    
    .f4-st102 {
        fill: url(#f4-XMLID_114_);
    }
    
    .f4-st103 {
        fill: url(#f4-XMLID_115_);
    }
    
    .f4-st104 {
        fill: url(#f4-XMLID_116_);
    }
    
    .f4-st105 {
        fill: url(#f4-XMLID_117_);
    }
    
    .f4-st106 {
        fill: url(#f4-XMLID_118_);
    }
    
    .f4-st107 {
        fill: url(#f4-XMLID_119_);
    }
    
    .f4-st108 {
        fill: url(#f4-XMLID_120_);
    }
    
    .f4-st109 {
        fill: url(#f4-XMLID_121_);
    }
    
    .f4-st110 {
        fill: url(#f4-XMLID_122_);
    }
    
    .f4-st111 {
        fill: url(#f4-XMLID_123_);
    }
    
    .f4-st112 {
        fill: url(#f4-XMLID_124_);
    }
    
    .f4-st113 {
        fill: url(#f4-XMLID_125_);
    }
    
    .f4-st114 {
        fill: url(#f4-XMLID_126_);
    }
    
    .f4-st115 {
        fill: url(#f4-XMLID_127_);
    }
    
    .f4-st116 {
        fill: url(#f4-XMLID_128_);
    }
    
    .f4-st117 {
        fill: url(#f4-XMLID_129_);
    }
    
    .f4-st118 {
        fill: url(#f4-XMLID_130_);
    }
    
    .f4-st119 {
        fill: url(#f4-XMLID_131_);
    }
    
    .f4-st120 {
        fill: url(#f4-XMLID_132_);
    }
    
    .f4-st121 {
        fill: url(#f4-XMLID_133_);
    }
    
    .f4-st122 {
        fill: url(#f4-XMLID_134_);
    }
    
    .f4-st123 {
        fill: url(#f4-XMLID_135_);
    }
    
    .f4-st124 {
        fill: url(#f4-XMLID_136_);
    }
    
    .f4-st125 {
        fill: url(#f4-XMLID_137_);
    }
    
    .f4-st126 {
        fill: url(#f4-XMLID_138_);
    }
    
    .f4-st127 {
        fill: url(#f4-XMLID_139_);
    }
    
    .f4-st128 {
        fill: url(#f4-XMLID_140_);
    }
    
    .f4-st129 {
        fill: url(#f4-XMLID_141_);
    }
    
    .f4-st130 {
        fill: url(#f4-XMLID_142_);
    }
    
    .f4-st131 {
        fill: url(#f4-XMLID_143_);
    }
    
    .f4-st132 {
        fill: url(#f4-XMLID_144_);
    }
    
    .f4-st133 {
        fill: url(#f4-XMLID_145_);
    }
    
    .f4-st134 {
        fill: url(#f4-XMLID_146_);
    }
    
    .f4-st135 {
        fill: url(#f4-XMLID_147_);
    }
    
    .f4-st136 {
        fill: url(#f4-XMLID_148_);
    }
    
    .f4-st137 {
        fill: url(#f4-XMLID_149_);
    }
    
    .f4-st138 {
        fill: url(#f4-XMLID_150_);
    }
    
    .f4-st139 {
        fill: url(#f4-XMLID_151_);
    }
    
    .f4-st140 {
        fill: url(#f4-XMLID_152_);
    }
    
    .f4-st141 {
        fill: url(#f4-XMLID_153_);
    }
    
    .f4-st142 {
        fill: url(#f4-XMLID_154_);
    }
    
    .f4-st143 {
        fill: url(#f4-XMLID_155_);
    }
    
    .f4-st144 {
        fill: url(#f4-XMLID_156_);
    }
    
    .f4-st145 {
        fill: url(#f4-XMLID_157_);
    }
    
    .f4-st146 {
        fill: url(#f4-XMLID_158_);
    }
    
    .f4-st147 {
        fill: url(#f4-XMLID_159_);
    }
    
    .f4-st148 {
        fill: url(#f4-XMLID_160_);
    }
    
    .f4-st149 {
        fill: url(#f4-XMLID_161_);
    }
    
    .f4-st150 {
        fill: url(#f4-XMLID_162_);
    }
    
    .f4-st151 {
        fill: url(#f4-XMLID_163_);
    }
    
    .f4-st152 {
        fill: url(#f4-XMLID_164_);
    }
    
    .f4-st153 {
        fill: url(#f4-XMLID_165_);
    }
    
    .f4-st154 {
        fill: url(#f4-XMLID_166_);
    }
    
    .f4-st155 {
        fill: url(#f4-XMLID_167_);
    }
    
    .f4-st156 {
        fill: url(#f4-XMLID_168_);
    }
    
    .f4-st157 {
        fill: url(#f4-XMLID_169_);
    }
    
    .f4-st158 {
        fill: url(#f4-XMLID_170_);
    }
    
    .f4-st159 {
        fill: url(#f4-XMLID_171_);
    }
    
    .f4-st160 {
        fill: url(#f4-XMLID_172_);
    }
    
    .f4-st161 {
        fill: url(#f4-XMLID_173_);
    }
    
    .f4-st162 {
        fill: url(#f4-XMLID_174_);
    }
    
    .f4-st163 {
        fill: url(#f4-XMLID_175_);
    }
    
    .f4-st164 {
        fill: url(#f4-XMLID_176_);
    }
    
    .f4-st165 {
        fill: url(#f4-XMLID_177_);
    }
    
    .f4-st166 {
        fill: url(#f4-XMLID_178_);
    }
    
    .f4-st167 {
        fill: url(#f4-XMLID_179_);
    }
    
    .f4-st168 {
        fill: url(#f4-XMLID_180_);
    }
    
    .f4-st169 {
        fill: url(#f4-XMLID_181_);
    }
    
    .f4-st170 {
        fill: url(#f4-XMLID_182_);
    }
    
    .f4-st171 {
        fill: url(#f4-XMLID_183_);
    }
    
    .f4-st172 {
        fill: url(#f4-XMLID_184_);
    }
    
    .f4-st173 {
        fill: url(#f4-XMLID_185_);
    }
    
    .f4-st174 {
        fill: url(#f4-XMLID_186_);
    }
    
    .f4-st175 {
        fill: url(#f4-XMLID_187_);
    }
    
    .f4-st176 {
        fill: url(#f4-XMLID_188_);
    }
    
    .f4-st177 {
        fill: url(#f4-XMLID_189_);
    }
    
    .f4-st178 {
        fill: url(#f4-XMLID_190_);
    }
    
    .f4-st179 {
        fill: url(#f4-XMLID_191_);
    }
    
    .f4-st180 {
        fill: url(#f4-XMLID_192_);
    }
    
    .f4-st181 {
        fill: url(#f4-XMLID_193_);
    }
    
    .f4-st182 {
        fill: url(#f4-XMLID_194_);
    }
    
    .f4-st183 {
        fill: url(#f4-XMLID_195_);
    }
    
    .f4-st184 {
        fill: url(#f4-XMLID_196_);
    }
    
    .f4-st185 {
        fill: url(#f4-XMLID_197_);
    }
    
    .f4-st186 {
        fill: url(#f4-XMLID_198_);
    }
    
    .f4-st187 {
        fill: url(#f4-XMLID_199_);
    }
    
    .f4-st188 {
        fill: url(#f4-XMLID_200_);
    }
    
    .f4-st189 {
        fill: url(#f4-XMLID_201_);
    }
    
    .f4-st190 {
        fill: url(#f4-XMLID_202_);
    }
    
    .f4-st191 {
        fill: url(#f4-XMLID_203_);
    }
    
    .f4-st192 {
        fill: url(#f4-XMLID_204_);
    }
    
    .f4-st193 {
        fill: url(#f4-XMLID_205_);
    }
    
    .f4-st194 {
        fill: url(#f4-XMLID_206_);
    }
    
    .f4-st195 {
        fill: url(#f4-XMLID_207_);
    }
    
    .f4-st196 {
        fill: url(#f4-XMLID_208_);
    }
    
    .f4-st197 {
        fill: url(#f4-XMLID_209_);
    }
    
    .f4-st198 {
        fill: url(#f4-XMLID_210_);
    }
    
    .f4-st199 {
        fill: url(#f4-XMLID_211_);
    }
    
    .f4-st200 {
        fill: url(#f4-XMLID_212_);
    }
    
    .f4-st201 {
        fill: url(#f4-XMLID_213_);
    }
    
    .f4-st202 {
        fill: url(#f4-XMLID_214_);
    }
    
    .f4-st203 {
        fill: url(#f4-XMLID_215_);
    }
    
    .f4-st204 {
        fill: url(#f4-XMLID_216_);
    }
    
    .f4-st205 {
        fill: url(#f4-XMLID_217_);
    }
    
    .f4-st206 {
        fill: url(#f4-XMLID_218_);
    }
    
    .f4-st207 {
        fill: url(#f4-XMLID_219_);
    }
    
    .f4-st208 {
        fill: url(#f4-XMLID_220_);
    }
    
    .f4-st209 {
        fill: url(#f4-XMLID_221_);
    }
    
    .f4-st210 {
        fill: url(#f4-XMLID_222_);
    }
    
    .f4-st211 {
        fill: url(#f4-XMLID_223_);
    }
    
    .f4-st212 {
        fill: url(#f4-XMLID_224_);
    }
    
    .f4-st213 {
        fill: url(#f4-XMLID_225_);
    }
    
    .f4-st214 {
        fill: url(#f4-XMLID_226_);
    }
    
    .f4-st215 {
        fill: url(#f4-XMLID_227_);
    }
    
    .f4-st216 {
        fill: url(#f4-XMLID_228_);
    }
    
    .f4-st217 {
        fill: url(#f4-XMLID_229_);
    }
    
    .f4-st218 {
        fill: url(#f4-XMLID_230_);
    }
    
    .f4-st219 {
        fill: url(#f4-XMLID_231_);
    }
    
    .f4-st220 {
        fill: url(#f4-XMLID_232_);
    }
    
    .f4-st221 {
        fill: url(#f4-XMLID_233_);
    }
    
    .f4-st222 {
        fill: url(#f4-XMLID_234_);
    }
    
    .f4-st223 {
        fill: url(#f4-XMLID_235_);
    }
    
    .f4-st224 {
        fill: url(#f4-XMLID_236_);
    }
    
    .f4-st225 {
        fill: url(#f4-XMLID_237_);
    }
    
    .f4-st226 {
        fill: url(#f4-XMLID_238_);
    }
    
    .f4-st227 {
        fill: url(#f4-XMLID_239_);
    }
    
    .f4-st228 {
        fill: url(#f4-XMLID_240_);
    }
    
    .f4-st229 {
        fill: url(#f4-XMLID_241_);
    }
    
    .f4-st230 {
        fill: url(#f4-XMLID_242_);
    }
    
    .f4-st231 {
        fill: url(#f4-XMLID_243_);
    }
    
    .f4-st232 {
        fill: url(#f4-XMLID_244_);
    }
    
    .f4-st233 {
        fill: url(#f4-XMLID_245_);
    }
    
    .f4-st234 {
        fill: url(#f4-XMLID_246_);
    }
    
    .f4-st235 {
        fill: url(#f4-XMLID_247_);
    }
    
    .f4-st236 {
        fill: url(#f4-XMLID_248_);
    }
    
    .f4-st237 {
        fill: url(#f4-XMLID_249_);
    }
    
    .f4-st238 {
        fill: url(#f4-XMLID_250_);
    }
    
    .f4-st239 {
        fill: url(#f4-XMLID_251_);
    }
    
    .f4-st240 {
        fill: url(#f4-XMLID_252_);
    }
    
    .f4-st241 {
        fill: url(#f4-XMLID_253_);
    }
    
    .f4-st242 {
        fill: url(#f4-XMLID_254_);
    }
    
    .f4-st243 {
        fill: url(#f4-XMLID_255_);
    }
    
    .f4-st244 {
        fill: url(#f4-XMLID_256_);
    }
    
    .f4-st245 {
        fill: url(#f4-XMLID_257_);
    }
    
    .f4-st246 {
        fill: url(#f4-XMLID_258_);
    }
    
    .f4-st247 {
        fill: url(#f4-XMLID_259_);
    }
    
    .f4-st248 {
        fill: url(#f4-XMLID_260_);
    }
    
    .f4-st249 {
        fill: url(#f4-XMLID_261_);
    }
    
    .f4-st250 {
        fill: url(#f4-XMLID_262_);
    }
    
    .f4-st251 {
        fill: url(#f4-XMLID_263_);
    }
    
    .f4-st252 {
        fill: url(#f4-XMLID_264_);
    }
    
    .f4-st253 {
        fill: url(#f4-XMLID_265_);
    }
    
    .f4-st254 {
        fill: url(#f4-XMLID_266_);
    }
    
    .f4-st255 {
        fill: url(#f4-XMLID_267_);
    }
    
    .f4-st256 {
        fill: url(#f4-XMLID_268_);
    }
    
    .f4-st257 {
        fill: url(#f4-XMLID_269_);
    }
    
    .f4-st258 {
        fill: url(#f4-XMLID_270_);
    }
    
    .f4-st259 {
        fill: url(#f4-XMLID_271_);
    }
    
    .f4-st260 {
        fill: url(#f4-XMLID_272_);
    }
    
    .f4-st261 {
        fill: url(#f4-XMLID_273_);
    }
    
    .f4-st262 {
        fill: url(#f4-XMLID_274_);
    }
    
    .f4-st263 {
        fill: url(#f4-XMLID_275_);
    }
    
    .f4-st264 {
        fill: url(#f4-XMLID_276_);
    }
    
    .f4-st265 {
        fill: url(#f4-XMLID_277_);
    }
    
    .f4-st266 {
        fill: url(#f4-XMLID_278_);
    }
    
    .f4-st267 {
        fill: url(#f4-XMLID_279_);
    }
    
    .f4-st268 {
        fill: url(#f4-XMLID_280_);
    }
    
    .f4-st269 {
        fill: url(#f4-XMLID_281_);
    }
    
    .f4-st270 {
        fill: url(#f4-XMLID_282_);
    }
    
    .f4-st271 {
        fill: url(#f4-XMLID_283_);
    }
    
    .f4-st272 {
        fill: url(#f4-XMLID_284_);
    }
    
    .f4-st273 {
        fill: url(#f4-XMLID_285_);
    }
    
    .f4-st274 {
        fill: url(#f4-XMLID_286_);
    }
    
    .f4-st275 {
        fill: url(#f4-XMLID_287_);
    }
    
    .f4-st276 {
        fill: url(#f4-XMLID_288_);
    }
    
    .f4-st277 {
        fill: url(#f4-XMLID_289_);
    }
    
    .f4-st278 {
        fill: url(#f4-XMLID_290_);
    }
    
    .f4-st279 {
        fill: url(#f4-XMLID_291_);
    }
    
    .f4-st280 {
        fill: url(#f4-XMLID_292_);
    }
    
    .f4-st281 {
        fill: url(#f4-XMLID_293_);
    }
    
    .f4-st282 {
        fill: url(#f4-XMLID_294_);
    }
    
    .f4-st283 {
        fill: url(#f4-XMLID_295_);
    }
    
    .f4-st284 {
        fill: url(#f4-XMLID_296_);
    }
    
    .f4-st285 {
        fill: url(#f4-XMLID_297_);
    }
    
    .f4-st286 {
        fill: url(#f4-XMLID_298_);
    }
    
    .f4-st287 {
        fill: url(#f4-XMLID_299_);
    }
    
    .f4-st288 {
        fill: url(#f4-XMLID_300_);
    }
    
    .f4-st289 {
        fill: url(#f4-XMLID_301_);
    }
    
    .f4-st290 {
        fill: url(#f4-XMLID_302_);
    }
    
    .f4-st291 {
        fill: url(#f4-XMLID_303_);
    }
    
    .f4-st292 {
        fill: url(#f4-XMLID_304_);
    }
    
    .f4-st293 {
        fill: url(#f4-XMLID_305_);
    }
    
    .f4-st294 {
        fill: url(#f4-XMLID_306_);
    }
    
    .f4-st295 {
        fill: url(#f4-XMLID_307_);
    }
    
    .f4-st296 {
        fill: url(#f4-XMLID_308_);
    }
    
    .f4-st297 {
        fill: url(#f4-XMLID_309_);
    }
    
    .f4-st298 {
        fill: url(#f4-XMLID_310_);
    }
    
    .f4-st299 {
        fill: url(#f4-XMLID_311_);
    }
    
    .f4-st300 {
        fill: url(#f4-XMLID_312_);
    }
    
    .f4-st301 {
        fill: url(#f4-XMLID_313_);
    }
    
    .f4-st302 {
        fill: url(#f4-XMLID_314_);
    }
    
    .f4-st303 {
        fill: url(#f4-XMLID_315_);
    }
    
    .f4-st304 {
        fill: url(#f4-XMLID_316_);
    }
    
    .f4-st305 {
        fill: url(#f4-XMLID_317_);
    }
    
    .f4-st306 {
        fill: url(#f4-XMLID_318_);
    }
    
    .f4-st307 {
        fill: url(#f4-XMLID_319_);
    }
    
    .f4-st308 {
        fill: url(#f4-XMLID_320_);
    }
    
    .f4-st309 {
        fill: url(#f4-XMLID_321_);
    }
    
    .f4-st310 {
        fill: url(#f4-XMLID_322_);
    }
    
    .f4-st311 {
        fill: url(#f4-XMLID_323_);
    }
    
    .f4-st312 {
        fill: url(#f4-XMLID_324_);
    }
    
    .f4-st313 {
        fill: url(#f4-XMLID_325_);
    }
    
    .f4-st314 {
        fill: url(#f4-XMLID_326_);
    }
    
    .f4-st315 {
        fill: url(#f4-XMLID_327_);
    }
    
    .f4-st316 {
        fill: url(#f4-XMLID_328_);
    }
    
    .f4-st317 {
        fill: url(#f4-XMLID_329_);
    }
    
    .f4-st318 {
        fill: url(#f4-XMLID_330_);
    }
    
    .f4-st319 {
        fill: url(#f4-XMLID_331_);
    }
    
    .f4-st320 {
        fill: url(#f4-XMLID_332_);
    }
    
    .f4-st321 {
        fill: url(#f4-XMLID_333_);
    }
    
    .f4-st322 {
        fill: url(#f4-XMLID_334_);
    }
    
    .f4-st323 {
        fill: url(#f4-XMLID_335_);
    }
    
    .f4-st324 {
        fill: url(#f4-XMLID_336_);
    }
    
    .f4-st325 {
        fill: url(#f4-XMLID_337_);
    }
    
    .f4-st326 {
        fill: url(#f4-XMLID_338_);
    }
    
    .f4-st327 {
        fill: url(#f4-XMLID_339_);
    }
    
    .f4-st328 {
        fill: url(#f4-XMLID_340_);
    }
    
    .f4-st329 {
        fill: url(#f4-XMLID_341_);
    }
    
    .f4-st330 {
        fill: url(#f4-XMLID_342_);
    }
    
    .f4-st331 {
        fill: url(#f4-XMLID_343_);
    }
    
    .f4-st332 {
        fill: url(#f4-XMLID_344_);
    }
    
    .f4-st333 {
        fill: url(#f4-XMLID_345_);
    }
    
    .f4-st334 {
        fill: url(#f4-XMLID_346_);
    }
    
    .f4-st335 {
        fill: url(#f4-XMLID_347_);
    }
    
    .f4-st336 {
        fill: url(#f4-XMLID_348_);
    }
    
    .f4-st337 {
        fill: url(#f4-XMLID_349_);
    }
    
    .f4-st338 {
        fill: url(#f4-XMLID_350_);
    }
    
    .f4-st339 {
        fill: url(#f4-XMLID_351_);
    }
    
    .f4-st340 {
        fill: url(#f4-XMLID_352_);
    }
    
    .f4-st341 {
        fill: url(#f4-XMLID_353_);
    }
    
    .f4-st342 {
        opacity: 0.5;
    }
    
    .f4-st343 {
        fill: url(#f4-XMLID_354_);
    }
    
    .f4-st344 {
        fill: url(#f4-XMLID_355_);
    }
    
    .f4-st345 {
        fill: url(#f4-XMLID_356_);
    }
    
    .f4-st346 {
        fill: url(#f4-XMLID_357_);
    }
    
    .f4-st347 {
        fill: url(#f4-XMLID_358_);
    }
    
    .f4-st348 {
        fill: url(#f4-XMLID_359_);
    }
    
    .f4-st349 {
        fill: url(#f4-XMLID_360_);
    }
    
    .f4-st350 {
        fill: url(#f4-XMLID_361_);
    }
    
    .f4-st351 {
        fill: url(#f4-XMLID_362_);
    }
    
    .f4-st352 {
        fill: url(#f4-XMLID_363_);
    }
    
    .f4-st353 {
        fill: url(#f4-XMLID_365_);
    }
    
    .f4-st354 {
        fill: url(#f4-XMLID_367_);
    }
    
    .f4-st355 {
        fill: url(#f4-XMLID_369_);
    }
    
    .f4-st356 {
        fill: url(#f4-XMLID_371_);
    }
    
    .f4-st357 {
        fill: url(#f4-XMLID_374_);
    }
    
    .f4-st358 {
        fill: url(#f4-XMLID_375_);
    }
    
    .f4-st359 {
        fill: url(#f4-XMLID_376_);
    }
    
    .f4-st360 {
        fill: url(#f4-XMLID_377_);
    }
    
    .f4-st361 {
        fill: url(#f4-XMLID_378_);
    }
    
    .f4-st362 {
        fill: url(#f4-XMLID_379_);
    }
    
    .f4-st363 {
        fill: url(#f4-XMLID_380_);
    }
    
    .f4-st364 {
        fill: url(#f4-XMLID_381_);
    }
    
    .f4-st365 {
        fill: url(#f4-XMLID_382_);
    }
    
    .f4-st366 {
        fill: url(#f4-XMLID_383_);
    }
    
    .f4-st367 {
        fill: url(#f4-XMLID_384_);
    }
    
    .f4-st368 {
        fill: url(#f4-XMLID_385_);
    }
    
    .f4-st369 {
        fill: url(#f4-XMLID_386_);
    }
    
    .f4-st370 {
        fill: url(#f4-XMLID_387_);
    }
    
    .f4-st371 {
        fill: url(#f4-XMLID_388_);
    }
    
    .f4-st372 {
        fill: url(#f4-XMLID_389_);
    }
    
    .f4-st373 {
        fill: url(#f4-XMLID_390_);
    }
    
    .f4-st374 {
        fill: url(#f4-XMLID_391_);
    }
    
    .f4-st375 {
        fill: url(#f4-XMLID_392_);
    }
    
    .f4-st376 {
        fill: url(#f4-XMLID_393_);
    }
    
    .f4-st377 {
        fill: #8B96AF;
    }
    
    .f4-st378 {
        fill: #646E89;
    }
    
    .f4-st379 {
        opacity: 0.25;
        fill: url(#f4-XMLID_394_);
    }
    
    .f4-st380 {
        fill: #48506D;
    }
    
    .f4-st381 {
        fill: #343A56;
    }
    
    .f4-st382 {
        fill: #1C223A;
    }
    
    .f4-st383 {
        display: none;
        opacity: 0.44;
        fill: url(#f4-XMLID_395_);
    }
    
    .f4-st384 {
        opacity: 0.44;
        fill: #B3232B;
    }
    
    .f4-st385 {
        opacity: 0.4;
        fill: url(#f4-shadow_1_);
    }
    
    .f4-st386 {
        fill: #FFB69C;
    }
    
    .f4-st387 {
        fill: #0E4C87;
    }
    
    .f4-st388 {
        fill: #FFC114;
    }
    
    .f4-st389 {
        opacity: 0.25;
        fill: url(#f4-XMLID_396_);
    }
    
    .f4-st390 {
        fill: #FFA081;
    }
    
    .f4-st391 {
        opacity: 0.25;
        fill: url(#f4-XMLID_397_);
    }
    
    .f4-st392 {
        fill: #EAAD92;
    }
    
    .f4-st393 {
        fill: #CE5959;
    }
    
    .f4-st394 {
        fill: #9C3E4B;
    }
    
    .f4-st395 {
        fill: #C55160;
    }
    
    .f4-st396 {
        fill: #C52127;
    }