    .f13-st0{fill:url(#f13-blob-back_1_);}
    .f13-st1{opacity:0.5;fill:url(#f13-blob-path_1_);}
    .f13-st2{fill:#F49E80;}
    .f13-st3{fill:#3B77BE;}
    .f13-st4{fill:#62BDF3;}
    .f13-st5{fill:#D1EAF2;}
    .f13-st6{fill:#3797DB;}
    .f13-st7{fill:#EFAC7F;}
    .f13-st8{fill:#2D185D;}
    .f13-st9{fill:#45258F;}
    .f13-st10{fill:#9ED16F;}
    .f13-st11{fill:#5E2E0D;}
    .f13-st12{fill:#D19369;}
    .f13-st13{fill:#336DA8;}
    .f13-st14{fill:#4F3524;}
    .f13-st15{fill:#7C5438;}
    .f13-st16{fill:#96A65F;}
    .f13-st17{fill:#7772D6;}
    .f13-st18{fill:#FAE0B2;}
    .f13-st19{fill:#FFFFFF;}