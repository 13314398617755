#section-06 {
    position: relative;
    margin-top: 5vh;
    .anchor {
        position: absolute;
        top: 0;
        transform: translateY(-20vh);
    }
    .intro-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include responsive-properties('all',
            (padding-top, 0, 10vw),
            (padding-left, 0, 10vw)
        );
        h1 {
            color: $cobalt;
            margin-top: 15vw;
            @include media-breakpoint-up(sm) {
                margin-top: 1rem;
            }
            @include responsive-property(margin-bottom, 1rem, 2rem, 'all');
        }
        .lead {
            display: none;
            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    }
    .content-container {
        position: relative;
        width: 100%;
        margin-top: -45vw;
        .container-fluid {
            max-width: $x4;
            padding-left: 0;
            padding-right: 0;
            .row {
                margin-left: 0;
                margin-right: 0;
                .col {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .factoids {
        .row {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        .factoid-col {
            @include make-col-ready();
            @media (min-width: 480px) {
                @include make-col(12);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(8);
            }
            .factoid {
                position: relative;
                display: block;
                background-color: $cobalt;
                border-radius: 50%;
                margin: 1em 0;
                @include media-breakpoint-up(md) {
                    margin: 1.5em 0;
                }
                .factoid-heading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    padding: 1em;
                    @include media-breakpoint-up(md) {
                        padding: 2em;
                    }
                    @include media-breakpoint-up(xl) {
                        padding: 3em;
                    }
                    .heading {
                        font-family: $font-serif;
                        font-weight: $weight-bold;
                        margin: 1rem 0;
                        @include responsive-properties('all',
                            (font-size, 2em, 3.5em),
                            (line-height, 1.3, 1.1)
                        );
                    }
                }
                .factoid-image {
                    position: relative;
                    width: 100%;
                    opacity: 1;
                    transition: opacity $speed;
                    img {
                        display: block;
                        width: 100%;
                        border-radius: 50%;
                    }
                }
                &:hover {
                    .factoid-image {
                        opacity: 0;
                    }
                }
            }
        }
        .glide__arrows {
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
            .glide__arrow {
                margin: 0 1.5rem;
                width: 3.5em;
                height: 3.5em;
                border: none;
                border-radius: 50%;
                background-color: $cobalt;
                color: $white;
                @include responsive-property(font-size, 1em, 1.1em);
                &:hover {
                    background-color: $peach;
                }
                &:active {
                    background-color: $peach-dark;
                }
                &:focus {
                    outline: none;
                }
                &.glide__arrow--left {
                    padding-right: 0.75rem;
                }
                &.glide__arrow--right {
                    padding-left: 0.75rem;
                }
                .glide-btn-text {
                    display: none;
                }
            }
            @include media-breakpoint-up(md) {
                display: block;
                position: absolute;
                pointer-events: none;
                width: 100%;
                top: 33%;
                margin-bottom: 0rem;
                .glide__arrow {
                    display: block;
                    position: absolute;
                    pointer-events: all;
                    transform: translateY(-50%);
                    &.glide__arrow--left {
                        left: 0;
                    }
                    &.glide__arrow--right {
                        right: 0;
                    }
                }
            }
            @include media-breakpoint-up(xl) {
                top: 50%;
            }
        }
    }
}