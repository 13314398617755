#section-the {
    position: relative;
    z-index: 1;
    width: 100%;
    transition: margin-top $speed;
    @include responsive-properties(
        (margin-top, 50vw, 25vw),
        (margin-bottom, 25vw, 0vw),
    );
    .anchor {
        position: absolute;
        top: 30vw;
        transform: translateY(-50vh);
    }
    .read-more {
        position: relative;
        margin-top: -40vw;
    }
    .intro-container {
        h1 {
            color: $cobalt;
        }
        .lead {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
    .read-more-content-container {
        h2 {
            font-family: $font-sans;
            font-weight: $weight-bold;
            text-transform: uppercase;
            letter-spacing: 0.15em;
            @include responsive-properties('all',
                (font-size, 1.25em, 2em),
                (line-height, 1.4, 1.1)
            );
        }
        .lead {
            display: block;
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
        .svg-sidebar {
            svg {
                position: absolute;
                right: $grid-gutter-width;
                width: calc(50vw - (#{$grid-gutter-width} * 2));
            }
        }
    }
    .blob-back {
        background-image: url(../svg/frame-07.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 3em 0;
        margin-bottom: 1em;
        color: $white;
        p {
            color: $white;
        }
    }
    .the-logo {
        max-width: 280px;
        height: auto;
    }
    &.section-active {
        .read-more-btn {
            display: none;
        }
    }
}

#section-02 {
    &.section-active {
        & + #section-the {
            margin-top: 0;
        }
    }
}