    .f14-st0{fill:url(#f14-blob_1_);}
    .f14-st1{opacity:0.5;fill:url(#f14-blob-path_1_);}
    .f14-st2{fill:#CCD3E6;}
    .f14-st3{fill:#D2E9FF;}
    .f14-st4{fill:#9DD3FF;}
    .f14-st5{fill:#5E9AF2;}
    .f14-st6{fill:#F0F8FF;}
    .f14-st7{fill:#FF7469;}
    .f14-st8{fill:#97C7FA;}
    .f14-st9{fill:#FF7A70;}
    .f14-st10{fill:#2E49D2;}
    .f14-st11{fill:#5F89D3;}
    .f14-st12{fill:#0F2B4E;}
    .f14-st13{fill:#FF9490;}
    .f14-st14{fill:#003261;}
    .f14-st15{fill:#FF8585;}
    .f14-st16{fill:#E3F0FD;}
    .f14-st17{fill:#FF5549;}
    .f14-st18{fill:#E05344;}
    .f14-st19{fill:#00448C;}
    .f14-st20{fill:#2776CE;}

    .f14-st13b{fill:#b57b64;}
    .f14-st15b{fill:#9f6d5a;}