	.f11-st0{fill:url(#f11-blob_1_);}
	.f11-st1{opacity:0.5;fill:url(#f11-blob-separator_1_);}
	.f11-st2{fill:#F49E80;}
	.f11-st3{fill:#50BFA5;}
	.f11-st4{fill:#F48C7F;}
	.f11-st5{fill:#F7CC7F;}
	.f11-st6{fill:#3C8F7C;}
	.f11-st7{fill:#355389;}
	.f11-st8{fill:#466EB6;}
	.f11-st9{fill:#86B1F2;}
	.f11-st10{fill:#B75742;}
	.f11-st11{fill:#F47458;}
	.f11-st12{fill:#FAE0B2;}
	.f11-st13{fill:#9BC1F9;}
	.f11-st14{fill:#FFFFFF;}
	.f11-st15{fill:#96D9C9;}
	.f11-st16{fill:#184655;}
	.f11-st17{fill:#AF6E52;}
	.f11-st18{fill:#13375B;}
	.f11-st19{fill:#7F4D3C;}
	#f11-pin {
		display: none;
		@include media-breakpoint-up(md) {
			display: block;
		}
	}
	.f11-pin-st0{fill:none;stroke:#5E368A;stroke-width:2;stroke-miterlimit:10;}
	.f11-pin-st1{fill:#5E368A;}