#section-12 {
    position: relative;
    width: 100%;
    .anchor {
        position: absolute;
        top: 28.125vw;
        transform: translateY(-50vh);
    }
    .intro-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 15vw;
        h1 {
            color: $cobalt;
        }
    }
    .content-container {
        position: relative;
        width: 100%;
        margin-top: -50vw;
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
            .row {
                margin-left: 0;
                margin-right: 0;
                .col {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .thumbnail-row {
                margin: 8vw 4.2vw 0;
                & > div {
                    @include media-breakpoint-up(md) {
                        padding-left: $grid-gutter-width / 4;
                        padding-right: $grid-gutter-width / 4;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-left: $grid-gutter-width / 3;
                        padding-right: $grid-gutter-width / 3;
                    }
                    @include media-breakpoint-up(xl) {
                        padding-left: $grid-gutter-width / 2;
                        padding-right: $grid-gutter-width / 2;
                    }
                }
            }
        }
    }
}