#section-09 {
    position: relative;
    width: 100%;
    h1 {
        color: $cobalt;
    }
    .intro-title-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        h1 {
            background-color: $white;
            @include responsive-property(margin-top, 12vw, 15vw);
        }
    }
    .content-background-container,
    .content-container {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
    }
    .content-background-container {
        .content-background {
            background: #2757e1;
            background: linear-gradient(90deg, rgba(10,164,184,1) 0%, rgba(39,87,225,1) 100%);
            width: 100vw;
            height: 50vh;
            transform: translateY(-50vh);
            @include media-breakpoint-up(xl) {
                width: 50vw;
                margin-left: 50vw;
            }
        }
    }
    .content-container {
        .container-fluid {
            transform: translateY(-50vh);
            & > .row {
                height: 50vh;
                padding-bottom: 2vw;
                .content-col-1,
                .content-col-2 {
                    @include make-col-ready();
                }
                .content-col-1 {
                    @include make-col-offset(2);
                    @include make-col(10);
                    display: none;
                    @include media-breakpoint-up(xl) {
                        display: block;
                    }
                    .content-title-container {
                        h1 {
                            @include responsive-property(margin-top, 1vw, 4vw);
                        }
                    }
                }
                .content-col-2 {
                    @include make-col-offset(2);
                    @include make-col(22);
                    padding-right: 0;
                    padding-left: 0;
                    color: $white;
                    @include media-breakpoint-up(xl) {
                        @include make-col-offset(0);
                        @include make-col(12);
                    }
                    .research {
                        .research-content-container  {
                            width: 100%;
                            height: calc(50vh - (3em + 2vw));
                            overflow-x: hidden;
                            overflow-y: auto;
                            .research-content {
                                padding-top: 1.5em;
                                padding-left: calc(1em + 2vw);
                                padding-right: calc(1em + 2vw);
                                padding-bottom: 1em;
                                a {
                                    color: $white;
                                    text-decoration: underline;
                                    &:hover {
                                        color: #FFC325;
                                    }
                                }
                            }
                        }
                        .glide__arrows__container {
                            width: 100%;
                            padding-left: calc(1em + 2vw);
                            padding-right: calc(1em + 2vw);
                            height: calc(2em + 2vw);
                            @include media-breakpoint-up(sm) {
                                height: calc(1em + 2vw);
                            }
                            .glide__arrows {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-top: 1px solid $white;
                                .glide__arrow {
                                    border: none;
                                    background: transparent;
                                    color: $white;
                                    font-size: 1rem;
                                    &:hover {
                                        color: #FFC325;
                                    }
                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}