	.f17-st0{fill:url(#f17-blob_1_);}
	.f17-st1{fill:#73C0FF;}
	.f17-st2{fill:#4DA8F2;}
	.f17-st3{fill:#B5DEFF;}
	.f17-st4{fill:#FFFFFF;}
	.f17-st5{fill:url(#f17-XMLID_2_);}
	.f17-st6{fill:#F5F7FF;}
	.f17-st7{fill:#EEF0F7;}
	.f17-st8{fill:#D9C7C1;}
	.f17-st9{fill:#99592E;}
	.f17-st10{fill:#1C468A;}
	.f17-st11{fill:#60381D;}
	.f17-st12{fill:#EDDAD3;}
	.f17-st13{fill:#5073AB;}
	.f17-st14{fill:#323657;}
	.f17-st15{fill:#DFE1E8;}
	.f17-st16{fill:#78C2FF;}
	.f17-st17{fill:#1A598C;}
	.f17-st18{fill:#2273B5;}
	.f17-st19{fill:#3C4145;}
	.f17-st20{fill:#EEA886;}
	.f17-st21{fill:#E55353;}
	.f17-st22{fill:#A77860;}
	.f17-st23{fill:#BD4444;}
	.f17-st24{fill:#949494;}
	.f17-st25{fill:#F3F3F3;}
	.f17-st26{fill:#706865;}
	.f17-st27{fill:#B59074;}
	.f17-st28{fill:#E2D2CA;}
	.f17-st29{fill:#4667A8;}
	.f17-st30{fill:#5F8BE3;}
	.f17-st31{fill:#5C5552;}
	.f17-st32{fill:#CECFD6;}
	.f17-st33{fill:#EEA987;}
	.f17-st34{fill:#E8734D;}
	.f17-st35{fill:#C78D71;}
	.f17-st36{fill:#FFC05C;}
	.f17-st37{fill:#424773;}
	.f17-st38{fill:#292C47;}
	.f17-st39{fill:#3A3F47;}
	.f17-st40{fill:#2E436E;}
	.f17-st41{fill:#918783;}
	.f17-st42{fill:#5E5854;}
	.f17-st43{fill:#BFB2AC;}
	.f17-st20b {fill:#9f6d5a;}