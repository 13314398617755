    .f20-st0{fill:url(#f20-blob_1_);}
    .f20-st1{fill:#FFFFFF;}
    .f20-st2{fill:#7471B3;}
    .f20-st3{fill:#3E95D1;}
    .f20-st4{fill:#6ABAE8;}
    .f20-st5{fill:#9DCD6F;}
    .f20-st6{fill:#462F8B;}
    .f20-st7{fill:#FFC226;}
    .f20-st8{fill:#95A55F;}
    .f20-st9{fill:#3D77BB;}
    .f20-st10{fill:#753F1A;}
    .f20-st11{fill:#0E4C87;}
    .f20-st12{fill:#F48561;}
    .f20-st13{fill:#9CA148;}
    .f20-st14{fill:#282827;}
    .f20-st15{fill:#E0AC69;}
    .f20-st16{fill:#C61D23;}
    .f20-st17{fill:#5F3589;}
    .f20-st18{fill:#CF9169;}
    .f20-st19{fill:#EEAC7F;}
    .f20-st20{fill:#0B4076;}
    .f20-st21{fill:#F7A391;}
    .f20-st22{fill:#9A9C9F;}
    .f20-st23{fill:#5D2F12;}
    .f20-st24{fill:#B4805D;}
    .f20-st25{fill:#2D225D;}
    .f20-st26{fill:#496CA7;}
    .f20-st27{fill:#8E5A3D;}