#section-14 {
    .profile-intro-container {
        .profile-intro {
            color: $cobalt;
        }
        .profile-intro-content {
            .profile-title {
                .profile-tag-container {
                    border-color: $cobalt;
                }
            }
        }
    }
}