#section-02 {
    position: relative;
    color: $white;
    margin-top: calc(-50vh);
    .section-02-content {
        position: relative;
        transform: translateY(-50%);
        transition: transform $speed;
    }
    .anchor {
        position: absolute;
        top: 0;
        transform: translateY(-50vh);
    }
    .lead {
        display: none;
    }
    .btn-white {
        background-color: #297BAE;
        &:hover {
            background-color: $white;
            color: #297BAE;
        }
    }
    .read-more-content {
        .lead {
            display: block;
        }
    }
    .blob-back {
        background-image: url(../svg/frame-07.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 2em 0;
        margin-bottom: 1em;
    }
    .img-moira {
        z-index: 0;
        position: absolute;
        overflow: hidden;
        width: calc(100% - (#{$grid-gutter-width} / 2));
        max-width: 200px;
        left: $grid-gutter-width / 2;
        top: 100%;
        left: 50%;
        transform: translate(calc(-50% + (#{$grid-gutter-width} / 4)), -100%);
        opacity: 0;
        transition: opacity ($speed * 2);
        @include media-breakpoint-up(sm) {
            width: calc(100% - #{$grid-gutter-width});
            max-width: none;
        }
        @include media-breakpoint-up(md) {
            transform: translate(-50%, calc(-100% - 1em));
        }
        svg {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .moira-mask-path {
        fill: $white;
    }
    .svg-sidebar {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 41.66667%;
        height: 0px;
        overflow: hidden;
        transform: translate(5%, 0);
        opacity: 0;
        display: none;
        transition: opacity ($speed * 2);
    }
    .svg-books {
        display: none;
        @include responsive-property(margin-top, 720px, 0px, 'all');
    }
    @media (min-width: 375px) {
        .svg-sidebar {
            max-width: 180px;
            transform: translate(7.5%, -5vw);
        }
    }
    @media (min-width: 480px) {
        .svg-sidebar {
            max-width: 240px;
            transform: translate(10%, -12vw);
        }
    }
    @include media-breakpoint-up(sm) {
        .svg-sidebar {
            max-width: 280px;
            transform: translate(20%, -12vw);
        }
    }
    @include media-breakpoint-up(md) {
        .lead {
            display: block;
        }
        .read-more-content {
            .lead {
                display: none;
            }
        }
        .svg-sidebar {
            display: block;
            width: 45%;
            max-width: none;
            transform: translate(5%, 380px);
        }
        .svg-books {
            display: block;
        }
    }
    @include media-breakpoint-up(lg) {
        .svg-sidebar {
            transform: translate(5%, 400px);
        }
    }
    @include media-breakpoint-up(xl) {
        .svg-sidebar {
            transform: translate(5%, 420px);
        }
    }
    @include media-breakpoint-up(x2) {
        .svg-sidebar {
            transform: translate(5%, 440px);
        }
    }
    @include media-breakpoint-up(x3) {
        .svg-sidebar {
            transform: translate(5%, 480px);
        }
    }
    @media (min-width: $x4) {
        .svg-sidebar {
            transform: translate(5%, 500px);
        }
    }
    @media (min-width: $x5) {
        .svg-sidebar {
            transform: translate(5%, 540px);
        }
    }
    @media (min-width: $x6) {
        .svg-sidebar {
            transform: translate(5%, 560px);
        }
    }
    &.section-activated {
        h1 {
            transition: color ($speed * 2);
            span {
                transition: color ($speed * 2);
            }
        }
        p {
            transition: color ($speed * 2);
        }
    }
    &.section-active {
        .section-02-content {
            transform: translateY(-16.66667vh);
        }
        .read-more-btn {
            display: none;
        }
        h1 {
            span {
                color: $cobalt;
            }
            .heading-italic-top,
            .heading-sans-btm {
                color: $teal-dark;
            }
        }
        p {
            color: $cobalt;
        }
        .blob-back {
            color: $white;
            p {
                color: $white;
            }
        }
        .img-moira {
            opacity: 1;
        }
        .svg-sidebar {
            opacity: 1;
            height: auto;
        }
    }
    .read-more-content-container {
        .read-more-content {
            padding: 0 0 1rem;
        }
    }
}