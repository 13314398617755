	.f8-st0{fill:#D4D4D5;}
	.f8-st1{fill:#FFCCCA;}
	.f8-st2{display:none;}
	.f8-st3{display:inline;}
	.f8-st4{fill:#A6DC20;}
	.f8-st5{fill:#6C9C00;}
	.f8-st6{fill:#007C82;}
	.f8-st7{fill:#FFFFFF;}
	.f8-st8{fill:#CEE4E6;}
	.f8-st9{fill:#AFCDCF;}
	.f8-st10{fill:#005258;}
	.f8-st11{fill:#FF7A47;}
	.f8-st12{fill:#5A3316;}
	.f8-st13{fill:#80B500;}
	.f8-st14{fill:#5B8300;}
	.f8-st15{fill:#896D28;}