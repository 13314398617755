	.f12-st0{fill:url(#f12-content-background_1_);}
	.f12-st1{fill:none;stroke:$cobalt;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:3,4;}
	.f12-st2{fill: $blaze;}
	.f12-st3{fill: $cobalt;}
	.f12-st4{fill:#DDD7EB;}
	.f12-st5{fill:#C1B8DB;}
	.f12-st6{fill:$blaze;}
	.f12-st7{clip-path:url(#f12-XMLID_5_);fill:$cobalt;}
	.f12-st8{fill:#EB8923;}
	.f12-st9{fill:#3E3574;}
	.f12-st10{fill:#EF599D;}
	.f12-st11{fill:#402F6D;}
	.f12-st12{fill:#FFFFFF;}
	.f12-st13{fill: #af6e52;}
	.f12-st14{fill:#7B5DA2;}
	.f12-st15{fill:#7562AB;}
	.f12-st16{fill:#972A79;}
	.f12-st17{fill:#F5B691;}
	.f12-st18{fill:#F2A18A;}
	.f12-st19{fill: $blaze;}
	.f12-st20{fill:#2CAB66;}
	.f12-st21{fill:#E11F26;}
	.f12-st22{fill:#DFD8EC;}
	.f12-st23{fill:#E75C25;}
	.f12-st24{fill-rule:evenodd;clip-rule:evenodd;fill:#D4D4D5;}
	.f12-st25{fill:#D4D4D5;}

	.f12-st6b{fill:#3E3574;}
	.f12-st6c{fill:#EB8923;}

	.f12-st18b{fill:#7c4f3c;}

	.f12-st13b{fill: #dd8f6d;}
	.f12-st13c{fill: #8B654A;}

	.f12-st11b{fill: #513223;}
	.f12-st11c{fill: #332017;}