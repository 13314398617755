.modal__overlay {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    position: relative;
    background-color: $white;
    border-radius: 0 2em 0 2em;
    box-sizing: border-box;
}

.modal__inner {
    padding: 1.5rem;
    width: 100%;
    min-width: 280px;
    max-height: 90vh;
    overflow-y: auto;
}

.modal__title {
    box-sizing: border-box;
}

.modal__close {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.5rem;
    width: 3.25em;
    height: 3.25em;
    text-align: center;
    border: none;
    border-radius: 50%;
    background-color: $cobalt;
    color: $white;
    @include responsive-property(font-size, 0.75em, 1.1em);
    &:hover {
        background-color: $peach;
    }
    &:active {
        background-color: $peach-dark;
    }
    &:focus {
        outline: none;
    }
}


/**********************\
    Animation Style
\**********************/

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.modal-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}